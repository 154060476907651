import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

/**** BEGIN KEY ACCOUNT RESERVATION */
// bulk create reservation
export const bulkCreateReservations = createAsyncThunk(
  "keyAccount/bulk-create-reservations",
  async (reservationData: any) => {
    try {
      const result = await axios.post(
        `key-account-bulk-create-reservations`,
        reservationData,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return error.response.data;
    }
  }
);

// bulk update reservation
export const bulkUpdateReservations = createAsyncThunk(
  "keyAccount/bulk-update-reservations",
  async (reservationData: any, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        `key-account-bulk-update-reservations`,
        reservationData,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// get All key account reservation
export const getReservationList = createAsyncThunk(
  "keyAccount/get-reservations-list",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get(`key-account-get-reservations-list`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Bulk cancel Reservation
export const bulkCancelReservations = createAsyncThunk(
  "keyAccount/bulk-cancel-reservations",
  async (reservationData: any, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        `key-account-bulk-cancel-reservations`,
        reservationData,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);
// cancel key account reservation
export const cancelSingleReservation = createAsyncThunk(
  "keyAccount/cancel-reservation",
  async (
    { reservationID, data }: { reservationID: String | undefined; data: any },
    { rejectWithValue }
  ) => {
    try {
      const result = await axios.put(
        `key-account-cancel-reservation/${reservationID}`,
        data,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// get reservation details
export const getReservationDetails = createAsyncThunk(
  "keyAccount/get-reservation-details",
  async (reservationNumber: any, { rejectWithValue }) => {
    try {
      const result = await axios.get(
        `key-account-get-reservation-details/${reservationNumber}`,
        {
          withCredentials: true,
        }
      );
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get reservation details
export const getArchivedReservationDetails = createAsyncThunk(
  "keyAccount/get-archived-reservation-details",
  async (reservationNumber: any, { rejectWithValue }) => {
    try {
      const result = await axios.get(
        `key-account-get-archived-reservation-details/${reservationNumber}`,
        {
          withCredentials: true,
        }
      );
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get All key account archived reservation
export const getArchivedReservationsList = createAsyncThunk(
  "keyAccount/get-archived-reservations-list",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get(
        `key-account-get-archived-reservations-list`,
        {
          withCredentials: true,
        }
      );
      if (!result.data.Success) {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get list of reservation to be exported on csv file
export const exportReservationsList = createAsyncThunk(
  "keyAccount/export-reservations-list",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        `key-account-export-reservations-list`,
        data,
        {
          withCredentials: true,
        }
      );
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
// resend reservation qrcode
export const resendKeyAccountReservation = createAsyncThunk(
  "keyAccount/resend-reservation",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(`key-account-resend-reservation`, data, {
        withCredentials: true,
      });

      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Bulk delete Reservations
export const bulkDeleteReservations = createAsyncThunk(
  "keyAccount/bulk-delete-reservations",
  async (reservationData: any, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        `key-account-bulk-delete-reservations`,
        reservationData,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);
// delete key account reservation
export const deleteSingleReservation = createAsyncThunk(
  "keyAccount/delete-reservation",
  async (
    { reservationID, data }: { reservationID: String | undefined; data: any },
    { rejectWithValue }
  ) => {
    try {
      const result = await axios.put(
        `key-account-delete-reservation/${reservationID}`,
        data,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// get event names list
export const getEventNamesList = createAsyncThunk(
  "keyAccount/get-event-names-list",
  async (parkingId:any, { rejectWithValue }) => {
    try {
      const result = await axios.get(`key-account-get-event-names-list/${parkingId}`, {
        withCredentials: true,
      });
      if (!result.data.Success) {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
/**** END KEY ACCOUNT RESERVATION */

const KeyAccountReservation = createSlice({
  name: "keyAccountReservations",
  initialState: {
    reservation: {},
    reservations: [],
    loading: false,
    success: false,
    description: null,
    errors: null,
    availablePlaces: null,
    exportedReservations: [],
    eventNames: [],
  },
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    builder
      //bulk add reservation
      .addCase(bulkCreateReservations.pending, (state) => {
        state.success = false;
        state.loading = true;
        state.errors = null;
        state.description = null;
      })
      .addCase(bulkCreateReservations.fulfilled, (state, action) => {
        state.success = action.payload.Success;
        state.loading = false;
        state.errors = null;
        state.reservations = action.payload.Reservations;
        state.description = action.payload.Description;
      })
      .addCase(bulkCreateReservations.rejected, (state: any, action: any) => {
        state.loading = false;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
        state.errors = action.payload.Description;
      })
      //bulk update reservation
      .addCase(bulkUpdateReservations.pending, (state) => {
        state.success = false;
        state.loading = true;
        state.errors = null;
        state.description = null;
      })
      .addCase(bulkUpdateReservations.fulfilled, (state, action) => {
        state.success = action.payload.Success;
        state.loading = false;
        state.errors = null;
        state.reservations = action.payload.Reservations;
        state.description = action.payload.Description;
      })
      .addCase(bulkUpdateReservations.rejected, (state: any, action: any) => {
        state.loading = false;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
        state.errors = action.payload.Description;
      })
      // get all key account reservation
      .addCase(getReservationList.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.errors = null;
        state.description = null;
      })
      .addCase(getReservationList.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.reservations = action.payload.Reservations;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(getReservationList.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      // gc cancel reservation
      .addCase(cancelSingleReservation.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(cancelSingleReservation.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.reservation = action.payload.Reservations;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(cancelSingleReservation.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      // bulk cancel reservation
      .addCase(bulkCancelReservations.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(bulkCancelReservations.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.reservations = action.payload.Reservations;
        state.reservation = action.payload.selectedReservation;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(bulkCancelReservations.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      // get reservation details
      .addCase(getReservationDetails.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(getReservationDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.reservation = action.payload.Reservation;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(getReservationDetails.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      // resend reservation qrcode
      .addCase(resendKeyAccountReservation.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(resendKeyAccountReservation.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(
        resendKeyAccountReservation.rejected,
        (state: any, action: any) => {
          state.loading = false;
          state.errors = action.payload.Description;
          state.success = action.payload.Success;
          state.description = action.payload.Description;
        }
      )
      // get all key account archived reservations
      .addCase(getArchivedReservationsList.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.errors = null;
        state.description = null;
      })
      .addCase(getArchivedReservationsList.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.reservations = action.payload.Reservations;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(
        getArchivedReservationsList.rejected,
        (state: any, action: any) => {
          state.loading = false;
          state.errors = action.payload.Description;
          state.success = action.payload.Success;
          state.description = action.payload.Description;
        }
      )
      // get key account reservations list to be exported
      .addCase(exportReservationsList.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.errors = null;
        state.description = null;
      })
      .addCase(exportReservationsList.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.exportedReservations = action.payload.Reservations;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(exportReservationsList.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      // get archived reservation details
      .addCase(getArchivedReservationDetails.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(getArchivedReservationDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.reservation = action.payload.Reservation;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(
        getArchivedReservationDetails.rejected,
        (state: any, action: any) => {
          state.loading = false;
          state.errors = action.payload.Description;
          state.success = action.payload.Success;
          state.description = action.payload.Description;
        }
      )
      // gc delete reservation
      .addCase(deleteSingleReservation.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(deleteSingleReservation.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.reservation = action.payload.Reservations;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(deleteSingleReservation.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      // bulk delete reservation
      .addCase(bulkDeleteReservations.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.success = false;
        state.description = null;
      })
      .addCase(bulkDeleteReservations.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.reservations = action.payload.Reservations;
        state.reservation = action.payload.selectedReservation;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(bulkDeleteReservations.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      //get event names 
      .addCase(getEventNamesList.pending, (state) => {
        state.loading = true;
        state.success = false;
        state.errors = null;
        state.description = null;
      })
      .addCase(getEventNamesList.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.eventNames = action.payload.EventNames;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      .addCase(getEventNamesList.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.success = action.payload.Success;
        state.description = action.payload.Description;
      })
      
  },
});

export default KeyAccountReservation.reducer;
