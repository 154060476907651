import React, { FC, useEffect, useState } from "react";

// **
import { styled } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

// Icons imports
import Close from "mdi-material-ui/Close";

// Images imports
import Logo from "../../assets/logo/Effia-Logo.png";

// Redux imports
import { useAppDispatch } from "../../redux/hooks";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { ArrowLeft } from "mdi-material-ui";
import { MuiOtpInput } from "mui-one-time-password-input";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
} from "@mui/material";
import {
  twoFactorAuthCheckOtp,
  twoFactorAuthSendOtp,
} from "../../redux/auth/authSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Cookies } from "react-cookie";

interface ITwoFactorAuthProps {
  openLogin: any;
  handleClose: () => void;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const CustomizedButton = styled(LoadingButton)(({ theme }) => ({
  color: "white",
  backgroundColor: "#1b374c",
  fontFamily: "Fira Sans, sans-serif",
  padding: "1em 2em",
  margin: "10px 0",
  "&:hover": {
    backgroundColor: "#162c3d",
    color: "white",
  },
}));

export function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const TwoFactorAuth: FC<ITwoFactorAuthProps> = ({ openLogin, handleClose }) => {
  // **Translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language.toUpperCase();
  const dispatch = useAppDispatch();
  const userReducer = useSelector((state: any) => state.auth.user);

  const [dataSend, setDataSend] = useState<Object>({
    idUser: userReducer?.id,
    method: userReducer?.defaultTwoFactorMethod || "email",
    currentLanguage: currentLanguage ? currentLanguage : "FR"
  });
  const [dataCheck, setDataCheck] = useState<any>({
    idUser: userReducer?.id,
    otp: "",
    saveDevice: false,
  });
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [disabledSpan, setDisabledSpan] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [defaultMethod, setDefaultMethod] = useState(userReducer?.defaultTwoFactorMethod || "email");
  const [changeMethod, setChangeMethod] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [showError, setShowError] = useState("");

  // Select Phone or Email
  const handleRadioChange = (event: any) => {
    setDefaultMethod(event.target.value);
    setDataSend({ ...dataSend, method: event.target.value });
  };

  // Change TwoFactorAuth Method
  const changeAnotherMethod = () => {
    setChangeMethod(true);
    setShowError("");
    setDataCheck({
      ...dataCheck,
      otp: "",
    })
  };

  // Next BTN when select method
  const handleNext = () => {
    setChangeMethod(false);
    // send otp
    if(dataSend){
      dispatch(twoFactorAuthSendOtp(dataSend)).then((result: any) => {
        if (result?.payload) {
          const decription = t(result?.payload?.Description);
          if (result?.payload?.Success) {
            toast.success(decription, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(decription, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    }
  };

  // Set OTP
  const handleChangeOTP = (value: any) => {
    setDataCheck({
      ...dataCheck,
      otp: value,
    });
    if (value.length === 6) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  };

  // Save device (switch)
  const handleChangeSwitch = (event: any) => {
    setDataCheck({
      ...dataCheck,
      saveDevice: event.target.checked,
    });
  };

  // resend otp
  const resendCode = () => {
    // resend otp
    if(dataSend){
      dispatch(twoFactorAuthSendOtp(dataSend)).then((result: any) => {
        if (result.payload) {
          const decription = t(result.payload.Description);
          if (result.payload.Success) {
            toast.success(decription, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(decription, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    }
    
    // disable btn + set count 60 s
    setDisabledSpan(true);
    let timer = 60;
    setCountdown(timer);
    const interval = setInterval(() => {
      timer -= 1;
      setCountdown(timer);
      if (timer === 0) {
        clearInterval(interval);
        setDisabledSpan(false);
      }
    }, 1000);
  };

  // verify otp is true or false
  const handleCheckOTP = () => {
    setLoadingBtn(true);
    if(dataCheck){
      dispatch(twoFactorAuthCheckOtp(dataCheck)).then((result: any) => {
        if (result?.payload) {
          if (result?.payload?.Success) {
            setShowError("");
            handleClose()
            setLoadingBtn(false);
          } else {
            setLoadingBtn(false);
            setShowError(t(result?.payload?.Description));
          }
        }
      });
    }
  };

  // Hide Email
  const hideEmail = (email: any) => {
    if (email) {
      const [username, domain] = email.split("@");

      if (username.length <= 3 * 2) {
        const visiblePart = username.slice(0, username.length - 4);
        const hiddenPart = "*".repeat(username.length - visiblePart.length);
        return `${visiblePart}${hiddenPart}@${domain}`;
      } else {
        const visiblePart1 = username.slice(0, 3);
        const hiddenPart = "*".repeat(username.length - 3 * 2);
        const visiblePart2 = username.slice(username.length - 3);
        return `${visiblePart1}${hiddenPart}${visiblePart2}@${domain}`;
      }
    }
  };

  // Hide Phone
  const hidePhoneNumber = (phoneNumber: any) => {
    if (phoneNumber) {
      const formattedPhoneNumber = phoneNumber.replace(/ /g, "");

      if (formattedPhoneNumber.length <= 3 * 2) {
        const visiblePart = formattedPhoneNumber.slice(
          0,
          formattedPhoneNumber.length - 4
        );
        const hiddenPart = "*".repeat(
          formattedPhoneNumber.length - visiblePart.length
        );
        return `${visiblePart}${hiddenPart}`;
      } else {
        const visiblePart1 = formattedPhoneNumber.slice(0, 3);
        const hiddenPart = "*".repeat(formattedPhoneNumber.length - 3 * 2);
        const visiblePart2 = formattedPhoneNumber.slice(
          formattedPhoneNumber.length - 3
        );
        return `${visiblePart1}${hiddenPart}${visiblePart2}`;
      }
    }
  };

  return (
    <>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "93%",
          }}
        >
          <ArrowLeft
            sx={{ color: "gray", cursor: "pointer" }}
            onClick={openLogin}
          />
          <img width={88} src={Logo} alt="" style={{ margin: "0 auto" }} />
        </Box>
      </BootstrapDialogTitle>
      <DialogContent
        dividers
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: ["0px 10px 30px 10px", "0px 95px 30px 95px"],
          overflowX: "hidden",
        }}
      >
        <Box sx={{ margin: "30px auto 0px auto" }}>
          <Typography
            sx={{
              textAlign: "center",
              color: "#484848",
              fontWeight: 500,
              fontSize: "24px",
              mb: 2,
              textTransform: "uppercase",
            }}
            variant="h3"
          >
            {t("profile.editProfile.titleTwoFactor")}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              textAlign: "center",
              color: "#8A8A8A",
              fontSize: "15px",
              lineHeight: "16px",
            }}
          >
            {t("decriptionTwoFactorModal")}
          </Typography>
        </Box>

        {!changeMethod ? (
          <>
            <Box>
              {/* SEND CODE INTERFACE */}
              <Typography
                sx={{
                  fontSize: "14px",
                  padding: "5px 0px 5px 0px",
                  color: "black",
                }}
              >
                {defaultMethod === "phone" && userReducer?.phoneVerified ? (
                  <>
                    {t(
                      "Nous venons de vous envoyer un code de 6 chiffres sur le numéro de Téléphone"
                    )}{" "}
                    <Typography component="span" style={{ fontWeight: "bold" }}>
                      {hidePhoneNumber(userReducer?.userPhone)}
                    </Typography>
                  </>
                ) : (
                  <>
                    {t(
                      "Nous venons de vous envoyer un code de 6 chiffres à l'adresse e-mail"
                    )}{" "}
                    <Typography component="span" style={{ fontWeight: "bold" }}>
                      {hideEmail(userReducer?.userEmail)}
                    </Typography>
                  </>
                )}
                . {t("Le code expire dans 10 minutes")}.
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                  padding: "5px 0px 10px 0px",
                  color: "black",
                }}
              >
                {t("Entrez le code à 6 chiffres")}:*{" "}
                {!disabledSpan ? (
                  <Typography
                    onClick={() => resendCode()}
                    component="span"
                    style={{ color: "blue", cursor: "pointer" }}
                  >
                    {t("Renvoyer le code")}.
                  </Typography>
                ) : (
                  <Typography
                    component="span"
                    style={{ color: "gray", cursor: "initial" }}
                  >
                    {t("Renvoyer dans")} {countdown} {t("secondes")}.
                  </Typography>
                )}
              </Typography>
              <MuiOtpInput
                value={dataCheck?.otp || ""}
                onChange={(value) => handleChangeOTP(value)}
                length={6}
                sx={{
                  gap:"6px",
                  ".MuiOtpInput-TextField": {
                  },
                  ".MuiOutlinedInput-input": {
                    padding:{
                      xs:"10px",
                      sm:"15px",
                      md:"18px",
                      lg:"20px",
                    }
                  },
                }}
              />
              {showError && (
                <FormHelperText
                  sx={{ color: "red", padding: "5px 0px 0px 5px" }}
                  id="outlined-weight-helper-text"
                >
                  {showError}
                </FormHelperText>
              )}
              {userReducer?.phoneVerified && (
                <Typography
                  sx={{
                    fontSize: "14px",
                    padding: "5px 0px 10px 0px",
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    cursor: "pointer",
                    "&:hover": {
                      color: "#F28C28",
                    },
                  }}
                  onClick={() => changeAnotherMethod()}
                >
                  {t("Choisir une autre méthode")} ...
                </Typography>
              )}
            </Box>
            <FormControlLabel
              value="start"
              control={
                <Switch
                  checked={dataCheck?.saveDevice || false}
                  onChange={(event) => handleChangeSwitch(event)}
                  color="primary"
                />
              }
              label={t("Enregistrer l'appareil")}
              labelPlacement="start"
            />
            <CustomizedButton
              loading={loadingBtn}
              onClick={handleCheckOTP}
              disabled={disabledBtn ? true : false}
              sx={{
                color: "white",
                backgroundColor: disabledBtn ? "#DCDCDC" : "#1b374c",
                cursor: disabledBtn ? "none" : "pointer",
                ".css-62e83j-MuiCircularProgress-root": { color: "white" },
              }}
            >
              {t("Activer")}
            </CustomizedButton>
          </>
        ) : changeMethod ? (
          <>
            <Box>
              {/* CHANGE METHOD */}
              <Typography
                sx={{
                  fontSize: "14px",
                  padding: "5px 0px 5px 0px",
                  color: "black",
                  fontWeight: "500",
                }}
              >
                {t("Choisissez votre méthode d'authentification")}:
              </Typography>
              <Box
                sx={{
                  border: "thin solid #d9d9d9",
                  borderRadius: "0.4rem",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "5px",
                    "&:not(:last-child)": {
                      borderBottom: "1px solid #d9d9d9",
                    },
                  }}
                >
                  <Typography>{t("Email")}</Typography>
                  <Radio
                    checked={defaultMethod === "email"}
                    onChange={(event) => handleRadioChange(event)}
                    value="email"
                    name="email"
                    inputProps={{ "aria-label": "A" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "5px",
                  }}
                >
                  {!userReducer?.phoneVerified ? (
                    <Tooltip
                      title={t("Votre numéro de téléphone n'est pas activé")}
                      arrow
                      placement="right"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <Typography style={{ color: "gray" }}>
                          {t("Numéro de téléphone")} (SMS)
                        </Typography>
                        <Radio
                          checked={defaultMethod === "phone"}
                          onChange={(event) => handleRadioChange(event)}
                          value="phone"
                          name="phone"
                          disabled={true}
                          inputProps={{ "aria-label": "A" }}
                        />
                      </Box>
                    </Tooltip>
                  ) : (
                    <>
                      <Typography>{t("Numéro de téléphone")}</Typography>
                      <Radio
                        checked={defaultMethod === "phone"}
                        onChange={(event) => handleRadioChange(event)}
                        value="phone"
                        name="phone"
                        inputProps={{ "aria-label": "A" }}
                      />
                    </>
                  )}
                </Box>
              </Box>
            </Box>
            <CustomizedButton
              loading={loadingBtn}
              onClick={() => handleNext()}
              sx={{ color: "white" }}
            >
              {t("Suivant")}
            </CustomizedButton>
          </>
        ) : null}
      </DialogContent>
    </>
  );
};

export default TwoFactorAuth;
