import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { Button, TextField, Typography } from "@mui/material";
import Isabelle from "./../../assets/images/isabelle.svg";
import CloseIcon from "mdi-material-ui/Close";
import SendIcon from "mdi-material-ui/Send";
import CustomFab from "./layout/CustomFab";
import { CustomMessages, TMessage } from "./layout/CustomMessages";
import TypingMessage from "./layout/TypingMessage";
import {
  chatBotContainer,
  ChatBotTextCOntainer,
  ChatPrimaryText,
  ChatSecondaryText,
  closeButtonDesign,
  discussionHeaderContainer,
  discussionHeaderContainerMobile,
  discussionInterfaceContainer,
  discussionInterfaceContainerMobile,
  floatingButtonDesign,
  floatingMessageDesign,
  IconContainer,
  IconImageDesign,
  MessagesContainerDesign,
  notifMessageDesign,
  notifTextDesign,
} from "../../styles/ChatBot/ChatBot.style";
import {
  sendMessageContainer,
  sendMessageInputDesign,
  typingInnerMessageContainer,
} from "../../styles/ChatBot/CustomMessages.style";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getChatBotResponse } from "../../common/data/chatBotAPI";
import { useTranslation } from "react-i18next";

const ChatBot = () => {
  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const isLessThanSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isFirstInteraction = localStorage.getItem("isFirstInteraction") || "0";
  const [shake, setShake] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [recievedMessagesNotif, setRecievedMessagesNotif] =
    useState<boolean>(false);
  const [openDiscussion, setOpenDiscussion] = useState<boolean>(false);
  const [messages, setMessages] = useState<TMessage[]>([]);
  const [messageInput, setMessageInput] = useState("");
  const [messageToDisplay, setMessageToDisplay] = useState<string | null>(null);
  const [pending, setPending] = useState<boolean>(false);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollTop = chatEndRef.current.scrollHeight;
    }
  }, [messages, openDiscussion]);

  useEffect(() => {
    if (messages.length === 0 && isFirstInteraction === "0") {
      localStorage.setItem("isFirstInteraction", "1");
      setTimeout(async () => {
        setPending(true);
        try {
          const currentLanguage = i18n.language;
          let startingMessage =
            currentLanguage?.toUpperCase() === "FR"
              ? "Bonjour, t'es qui?"
              : currentLanguage?.toUpperCase() === "EN"
              ? "Hello, who are you?"
              : "Hallo, wie ben jij?";
          const res = await getChatBotResponse(
            `${process.env.REACT_APP_CHATBOT}/chat`,
            startingMessage
          );
          if (res.data.response) {
            const text = res.data.response;
            setMessageToDisplay(text.slice(0, 20) + "...");
          }
          setMessages((messages) => [
            ...messages,
            { text: res.data.response, user: "other" },
          ]);
        } catch {
          setMessages((messages) => [
            ...messages,
            { text: t("Service non disponible pour le moment"), user: "other" },
          ]);
        } finally {
          setPending(false);
          if (!openDiscussion) {
            handlesShake();
            handleShowMessage();
          }
        }
      }, 10000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSendMessage = async (msg: string, user: "me" | "other") => {
    if (msg.trim() === "") return;
    setPending(true);
    try {
      if (user === "me") {
        setMessages((messages) => [...messages, { text: msg, user: user }]);
        setMessageInput("");
        const res = await getChatBotResponse(
          `${process.env.REACT_APP_CHATBOT}/chat`,
          msg
        );
        if (res.data.response) {
          const text = res.data.response;
          setMessageToDisplay(text.slice(0, 20) + "...");
        }
        setMessages((messages) => [
          ...messages,
          { text: res.data.response, user: "other" },
        ]);
      } else {
        setMessages((messages) => [...messages, { text: msg, user }]);
      }
    } catch {
      setMessages((messages) => [
        ...messages,
        { text: t("Service non disponible pour le moment"), user: "other" },
      ]);
    } finally {
      setPending(false);
      if (openDiscussion) {
        handlesShake();
        handleShowMessage();
      }
    }
  };
  const handlesShake = () => {
    setShake(true);
    setTimeout(() => setShake(false), 300);
  };
  const handleShowMessage = () => {
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
      setRecievedMessagesNotif(true);
      setMessageToDisplay(null);
    }, 2000);
  };
  const handleKeyDown = (e: any) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(messageInput, "me");
    }
  };
  const handleClick = () => {
    setRecievedMessagesNotif(false);
    setOpenDiscussion(!openDiscussion);
  };

  return (
    <Box sx={chatBotContainer}>
      <Box sx={floatingButtonDesign}>
        <CustomFab
          shake={shake}
          openDiscussion={openDiscussion}
          handleClick={handleClick}
        />
        {showMessage && !openDiscussion && (
          <Box sx={{ ...floatingMessageDesign }}>{messageToDisplay}</Box>
        )}
        {recievedMessagesNotif && !openDiscussion && (
          <Box sx={notifMessageDesign}>
            <Typography sx={notifTextDesign}>1</Typography>
          </Box>
        )}
      </Box>
      {pending &&
        !openDiscussion &&
        !recievedMessagesNotif &&
        !messageToDisplay && <TypingMessage sx={{ right: "-30%" }} />}
      {openDiscussion && (
        <Box
          sx={
            isLessThanSm
              ? discussionInterfaceContainerMobile
              : { ...discussionInterfaceContainer }
          }
        >
          <Box
            id="header"
            sx={
              isLessThanSm
                ? discussionHeaderContainerMobile
                : discussionHeaderContainer
            }
          >
            <Box sx={{display:"flex", gap:1, flex:7, paddingLeft:"15px"}}>
              <Box id="imageContainer" sx={IconContainer}>
                <img
                  src={Isabelle}
                  alt="isabelle"
                  unselectable="on"
                  style={IconImageDesign}
                  onContextMenu={(e) => e.preventDefault()}
                />
              </Box>
              <Box
                id="chatBotNameAndDescription"
                sx={{ ...ChatBotTextCOntainer }}
              >
                <Typography sx={{ ...ChatPrimaryText, marginBottom: "-5px" }}>
                  Isabelle
                </Typography>
                <Typography sx={ChatSecondaryText}>
                  {t("Votre assistante virtuelle")}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ ...closeButtonDesign, flex:1 }}>
              <Box
                onClick={handleClick}
                sx={{ cursor: "pointer", color: "white" }}
              >
                <CloseIcon />
              </Box>
            </Box>
          </Box>
          <Box
            id="ChatBotMessagingbody"
            ref={chatEndRef}
            sx={{...MessagesContainerDesign}}
          >
            {messages.map((message, index) => (
              <Box sx={{ width: "93%" }}>
                <CustomMessages index={index} message={message} />
              </Box>
            ))}
            {pending && openDiscussion && (
              <Box sx={typingInnerMessageContainer}>
                <TypingMessage />
              </Box>
            )}
          </Box>
          <Box
            id="footer"
            sx={sendMessageContainer}
          >
            <Box sx={{ flex: 6 }}>
              <TextField
                id="outlined-textarea"
                placeholder="Aa..."
                multiline
                fullWidth
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                maxRows={3}
                onKeyDown={handleKeyDown}
                InputProps={{ style: sendMessageInputDesign }}
              />
            </Box>
            <Button
              sx={{ flex: 1, color: "#1B374C" }}
              disabled={pending}
              onClick={() => handleSendMessage(messageInput, "me")}
            >
              <SendIcon />
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ChatBot;
