import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

//Get All Sultan Users
export const getAllTicketCategories = createAsyncThunk(
    "categories/getAll",
    async (_, { rejectWithValue }) => {
        try {
            const result = await axios.get(
                "/getAllCategories",
            );
            /*  if (result.data.Success) {
             } */
            return result.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

const CategorySlice = createSlice({
    name: "CategorySlice",
    initialState: {
        TicketCategory: {},
        TicketCategories: [],
        loading: false,
        errors: null,
        success: false,
    },
    reducers: {
        clearErrors: (state) => {
            state.errors = null;
        },
    },
    extraReducers: (builder) => {
        builder
            //Get All Sultan Users
            .addCase(getAllTicketCategories.pending, (state) => {
                state.loading = true;
                state.TicketCategory = {};
                state.TicketCategories = [];
                state.errors = null;
                state.success = false;
            })
            .addCase(getAllTicketCategories.fulfilled, (state, action) => {
                state.loading = false;
                state.TicketCategory = {};
                state.TicketCategories = action.payload.TicketCategories;
                state.errors = null;
                state.success = true;
            })
            .addCase(getAllTicketCategories.rejected, (state: any, action: any) => {
                state.loading = false;
                state.TicketCategory = {};
                state.TicketCategories = [];
                state.errors = action.payload.Description;
                state.success = false;
            })

    },
});


export const { clearErrors } = CategorySlice.actions;
export default CategorySlice.reducer;