// ** React imports
import React from "react";

// **Router imports
import { useNavigate } from "react-router-dom";

// ** MUI imports
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SvgIcon from "@material-ui/core/SvgIcon";
import Button from "@mui/material/Button";

// ** Third party imports
import { useTranslation } from "react-i18next";

// Components imports
import StaticPagesLayout from "../../components/layouts/staticPagesLayout";


const Page404 = () => {
  // Router

  // ** Translation
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StaticPagesLayout>
        <Box sx={{ mb: 2, display: "flex", flexDirection: "column", paddingTop:'4.5%'}}>
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            m: "auto",
          }}
        >
          <SvgIcon
            viewBox="-35 0 371 370"
            style={{
              width: "123px",
              height: "123px",
            }}
          >
            <path
              fill="#F89425"
              d="M150.881,17.519L0,284.241h301.761L150.881,17.519z M168.072,104.046l-6.85,105.73h-20.678l-6.85-105.73H168.072z M150.88,255.995c-9.755,0-17.663-7.908-17.663-17.663c0-9.755,7.908-17.663,17.663-17.663c9.755,0,17.663,7.908,17.663,17.663C168.543,248.087,160.635,255.995,150.88,255.995z"
            />
          </SvgIcon>
        </Box>
        
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: " 35.2px",
            width: "138.136px",
            height: "47.885px",
            lineHeight: "0%",
            textAlign: "center",
            m: "auto",
            color: "#1b374c",
          }}
        >
          Oops!
        </Typography>
        <Box
          sx={{
            m: "auto",
          }}
        >
        <Typography
          sx={{
            color: "#1b374c",
            fontSize: {xs:"150px",sm:"250px"},
            fontStyle: "normal",
            fontWeight: 970,
            textAlign: "center",
            lineHeight: "70%",
          }}
          variant="h1"
        >
          {404}
        </Typography>
        </Box>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: {xs: "15px", sm: "30px"},
            lineHeight: "200%",
            fontFamily: 'Poppins',
            textAlign: "center",
            m: "auto",
            color: "#F89425",
          }}
        >
          {t("Page introuvable")}
        </Typography>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: " 120%",
            lineHeight: "normal",
            fontFamily: 'Poppins',
            textAlign: "center",
            m: "auto",
          }}
        >
          {t("Cette Page n'existe pas ou a été supprimée!")}
        </Typography>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: " 100%",
            lineHeight: "normal",
            fontFamily: 'Poppins',
            textAlign: "center",
            m: "auto",
          }}
        >
          {t("Nous vous suggérons de retourner à l'accueil.")}
        </Typography>
        <Box
          sx={{
            m: "auto",
            marginTop: '22px'
          }}
        >
        <Button
              variant="outlined"
              sx={{
                fontWeight: 500,
                color: "#F89425",
                borderColor: "#F89425",
                fontSize: "20px",
              }}
              onClick={() => navigate("/")}
            >
              {t("Page d'acceuil")}
            </Button>
        </Box>
      </section>
      </Box>
    </StaticPagesLayout>
  );
};

export default Page404;
