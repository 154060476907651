import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";
import { currentUser } from "../redux/auth/authSlice";
import { useAppDispatch } from "../redux/hooks";

export interface IAuthContextProps {
  user: string;
  setUser?(...args: unknown[]): unknown;
  userData: any;
  userType: any;
}
const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
  children: ReactNode;
}
export const AuthContextProvider: FC<IAuthContextProviderProps> = ({
  children,
}) => {
  // States
  //eslint-disable-next-line
  const [cookie, setCookie] = useCookies(["x-sultan-token-1"]);
  const [user, setUser] = useState<string>(cookie["x-sultan-token-1"] || "");
  const [userData, setUserData] = useState<any>();
  const [userType, setUserType] = useState<string | null>(null);

  // Hooks
  const dispatch = useAppDispatch();

  // Get current user and set their info
  useEffect(() => {
    const getUser = async () => {
      await dispatch(currentUser())
        .then((res: any) => {
          setUserData(res.payload);
        })
        .catch((err: any) => {
          setUserData({});
        });
    };
    if (cookie["x-sultan-token-1"]) {
      getUser();
    } else {
      setUserData({});
    }
    //eslint-disable-next-line
  }, [cookie]);

  useEffect(() => {
    if (userData && userData.Data) {
      setUserType(userData.Data.userType || null);
    } else {
      setUserType(null);
    }
  }, [userData]);

  const value = useMemo(
    () => ({
      user,
      userData,
      setUser,
      userType,
    }),
    [user, userData, userType]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
