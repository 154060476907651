// Notification.tsx
import React from "react";
import { makeStyles, Box, Tooltip } from "@material-ui/core";
import { TrashCanOutline } from "mdi-material-ui";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import moment from "moment";

interface NotificationProps {
  id: number;
  isRead: boolean;
  content: string;
  createdAt: string;
  onDelete: (id: number) => void;
  onToggleRead: (id: number) => void;
}

const notificationStyles = makeStyles((theme) => ({
  notification: {
    display: "flex",
    width: "93%",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: (props: { isRead: boolean }) =>
      props.isRead === true ? "white" : "#edeff1",
    position: "relative",
  },
  icon: {
    width: "8px",
    height: "8px",
    marginRight: theme.spacing(2),
    backgroundColor: (props: { isRead: boolean }) =>
      props.isRead === true ? "white" : "#F89425",
    border: "1px solid #F89425",
    borderRadius: "50%",
    cursor: (props: { isRead: boolean }) =>
    props.isRead === true ? "default" : "pointer",
  },
  contentBox: {
    width: "80%",
    flexGrow: 1,
    textAlign: "start",
    maxWidth: "380px",
    marginRight: 8,
  },
  deleteIcon: {
    width: "10%",
    cursor: "pointer",
    color: "#1B374C50",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: theme.spacing(-1.5),
  },
  content:{
    fontSize: "15px",
    color: "#1B374C",
  },
  createdAt: {
    fontSize: "13px",
    color: "gray",
    display:"flex",
    alignItem: "end"
  }
}));

const Notification: React.FC<NotificationProps> = ({
  id,
  isRead,
  content,
  createdAt,
  onDelete,
  onToggleRead,
}) => {
  const { t,i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const classes = notificationStyles({ isRead });

  const handleDeleteClick = () => {
    onDelete(id);
  };

  const handleIconClick = (isRead: boolean) => {
    if (!isRead) {
      onToggleRead(id);
    }
  };

  return (
    <Box className={classes.notification}>
      <Tooltip
        title={isRead ? t("Lu") : t("marquer comme lu")}
      >
        <Box
          className={classes.icon}
          onClick={() => handleIconClick(isRead)}
        ></Box>
      </Tooltip>
      <Box
        className={classes.contentBox}
        sx={{
          fontWeight: isRead ? "400" : "500",
        }}
      >
        <Typography className={classes.content}> {t(`${content}`)} </Typography>
        <Typography className={classes.createdAt}>
          {createdAt && moment(createdAt).locale(currentLanguage).calendar()}
        </Typography>
      </Box>
      <Box className={classes.deleteIcon} onClick={handleDeleteClick}>
        <Tooltip title={t("Supprimer")}>
          <TrashCanOutline />
        </Tooltip>
      </Box>
    </Box>
  );
};

export default Notification;
