import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

// create new reservation
export const newReservation = createAsyncThunk(
  "reservation/newReservation",
  async ({ createReservInfo, userUpdateInfo }: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        `new-reservation`,
        { createReservInfo, userUpdateInfo },
        {
          withCredentials: true,
        }
      );
      if (!result.data.Success) {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// create new ucl reservation
export const newUclReservation = createAsyncThunk(
  "reservation/newUclReservation",
  async (reservationInfo: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(`new-ucl-reservation`, reservationInfo, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);
// get all user's reservation
export const getAllUserReservations = createAsyncThunk(
  "reservation/getAllUserReservations",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get(`get-user-reservations`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get reservation by id
export const getReservationById = createAsyncThunk(
  "reservation/getReservationById",
  async (reservationID: String | undefined, { rejectWithValue }) => {
    try {
      const result = await axios.get(`get-reservation/${reservationID}`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// cancel reservation
export const cancelReservation = createAsyncThunk(
  "reservation/cancelReservation",
  async (reservationID: String | undefined, { rejectWithValue }) => {
    try {
      const result = await axios.put(`cancel-reservation/${reservationID}`, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// delete reservation
export const deleteReservation = createAsyncThunk(
  "reservation/deleteReservation",
  async (reservationID: String | undefined, { rejectWithValue }) => {
    try {
      const result = await axios.put(`delete-reservation/${reservationID}`, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);
// cancel ucl reservation
export const cancelUclReservation = createAsyncThunk(
  "reservation/cancelUclReservation",
  async (
    { reservationID, data }: { reservationID: String | undefined; data: any },
    { rejectWithValue }
  ) => {
    try {
      const result = await axios.put(
        `cancel-ucl-reservation/${reservationID}`,
        data,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// cancel ucl reservation
export const upadteUclReservation = createAsyncThunk(
  "reservation/upadteUclReservation",
  async ({ reservationID, data }: any, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        `update-ucl-reservation/${reservationID}`,
        data,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// resend ucl reservation
export const uclResendReservation = createAsyncThunk(
  "reservation/resend-ucl-reservation",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(`resend-ucl-reservation`, data, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get list of ucl reservations to be exported on csv file
export const uclExportReservationsList = createAsyncThunk(
  "reservation/ucl-export-reservations-list",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(`export-ucl-reservations-list`, data, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get list of ucl reservations to be exported on csv file
export const uclArchivedReservationsList = createAsyncThunk(
  "reservation/ucl-archived-reservations-list",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get(`ucl-archived-reservations-list`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// delete ucl reservation
export const uclDeleteSingleReservation = createAsyncThunk(
  "reservation/ucl-delete-reservation",
  async (reservationID: string, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        `ucl-delete-reservation/${reservationID}`,
        {
          withCredentials: true,
        }
      );

      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//
const reservationSlice = createSlice({
  name: "reservation",
  initialState: {
    reservation: {},
    reservations: [],
    exportedReservations: [],

    loading: false,
    errors: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // create new reservation
      .addCase(newReservation.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(newReservation.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.reservation = action.payload.Reservation;
      })
      .addCase(newReservation.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
      })
      // create new reservation
      .addCase(newUclReservation.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(newUclReservation.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.reservations = action.payload.Data;
      })
      .addCase(newUclReservation.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
      })
      // get all user's reservation
      .addCase(getAllUserReservations.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(getAllUserReservations.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.reservations = action.payload.Reservations;
      })
      .addCase(getAllUserReservations.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
      })
      // get reservation by id
      .addCase(getReservationById.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(getReservationById.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.reservation = action.payload.Reservation;
      })
      .addCase(getReservationById.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
      })
      // cancel reservation
      .addCase(cancelReservation.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(cancelReservation.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.reservations = action.payload.Data;
      })
      .addCase(cancelReservation.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
      })
      // cancel ucl reservation
      .addCase(cancelUclReservation.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(cancelUclReservation.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.reservations = action.payload.Data;
      })
      .addCase(cancelUclReservation.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
      })
      // delete reservation
      .addCase(deleteReservation.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(deleteReservation.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.reservations = action.payload.Reservations;
      })
      .addCase(deleteReservation.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
      })
      // update ucl reservation
      .addCase(upadteUclReservation.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(upadteUclReservation.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.reservations = action.payload.Data;
      })
      .addCase(upadteUclReservation.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
      })

      // get ucl reservations list to be exported
      .addCase(uclExportReservationsList.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(uclExportReservationsList.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.exportedReservations = action.payload.Reservations;
      })
      .addCase(
        uclExportReservationsList.rejected,
        (state: any, action: any) => {
          state.loading = false;
          state.errors = action.payload.Description;
        }
      )
      // resend ucl reservation
      .addCase(uclResendReservation.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(uclResendReservation.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
      })
      .addCase(uclResendReservation.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
      })
      // get ucl archived reservations list
      .addCase(uclArchivedReservationsList.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(uclArchivedReservationsList.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.reservations = action.payload.Reservations;
      })
      .addCase(
        uclArchivedReservationsList.rejected,
        (state: any, action: any) => {
          state.loading = false;
          state.errors = action.payload.Description;
        }
      )
      //ucl delete reservation
      .addCase(uclDeleteSingleReservation.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(uclDeleteSingleReservation.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.reservations = action.payload.Reservations;
      })
      .addCase(
        uclDeleteSingleReservation.rejected,
        (state: any, action: any) => {
          state.loading = false;
          state.errors = action.payload.Description;
        }
      );
  },
});

export default reservationSlice.reducer;
