import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";

export const CustomizedButton = styled(Button)(({ theme }) => ({
  color: "white",
  backgroundColor: "#1b374c",
  fontFamily: "Fira Sans, sans-serif",
  padding: "1em 2em",
  margin: "3rem 0",
  "&:hover": {
    backgroundColor: "#162c3d",
    color: "white",
  },
}));

export const CustomizedLoadingBtn = styled(LoadingButton)(({ theme }) => ({
  color: "white",
  backgroundColor: "#1b374c",
  fontFamily: "Fira Sans, sans-serif",
  padding: "1em 2em",
  margin: "3rem 0",
  "&:hover": {
    backgroundColor: "#162c3d",
    color: "white",
  },
}));
