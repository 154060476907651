import React, { FC, useState, KeyboardEvent } from "react";

// **
import { styled } from "@mui/material/styles";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

// Icons imports
import Close from "mdi-material-ui/Close";

// Images imports
import Logo from "../../assets/logo/Effia-Logo.png";
import facebookIcon2 from "../../assets/icons/facebook.svg";
// import GoogleLogo from "../../assets/images/google.png";
// import googleIcon from "../../assets/icons/GoogleIcon.svg.png";
// import Facebook from "../../assets/images/facebook.png";
// import facebookIcon from "../../assets/icons/FacebookIcon.svg.png";
// import facebookLogo from "../../assets/images/Facebook-logo.png";

// Redux imports
import { useAppDispatch } from "../../redux/hooks";
import {
  CallbackFacebook,
  callbackGoogle,
  login,
  twoFactorAuthSendOtp,
} from "../../redux/auth/authSlice";

// ** Third party imports
// import FacebookBtn from "../../components/facebookBtn";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
// import FacebookLogin from "@greatsumini/react-facebook-login";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { FormHelperText, InputAdornment } from "@mui/material";
import { EyeOffOutline, EyeOutline, InformationOutline } from "mdi-material-ui";

const Login: FC<ILoginProps> = ({
  openForgotPsw,
  openRegister,
  handleClose,
  openTwoFactorAuth,
}) => {
  // **Translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language.toUpperCase();

  const [loginInfo, setLoginInfo] = useState<Object>({});
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorLogin, setErrorLogin] = useState("");

  // hooks
  const dispatch = useAppDispatch();

  // Check if client id google exist in env
  const cliendId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  // Check if client id facebook exist in env
  const facebookId = process.env.REACT_APP_FACEBOOK_CLIENT_ID;

  // functions
  // handle change login info inputs
  const handleChange = (e: any) => {
    setLoginInfo({ ...loginInfo, [e.target.name]: e.target.value });
  };

  // dispatch login user
  const handleLogin = () => {
    setLoadingBtn(true);
    dispatch(login(loginInfo)).then((result: any) => {
      setLoadingBtn(false);
      if (result?.payload) {
        const needVerif = result?.payload?.NeedVerification;

        if (result?.payload?.Success) {
          if (needVerif) {
            openTwoFactorAuth();
            const dataSend = {
              idUser: result?.payload?.User?.id,
              method: result?.payload?.User?.defaultTwoFactorMethod,
              currentLanguage: currentLanguage ? currentLanguage : "FR"
            };
            dispatch(twoFactorAuthSendOtp(dataSend)).then((result: any) => {
              if (result?.payload) {
                const description = t(result?.payload?.Description);
                if (result?.payload?.Success) {
                  toast.success(description, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(description, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              }
            });
          } else {
            handleClose();
          }
        } else {
          setErrorLogin(t(result?.payload?.Description) || t(result?.payload?.message));
        }
      }
    });
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  // FACEBOOK
  const responseFacebook = async (facebookUser: any) => {
    await dispatch(CallbackFacebook(facebookUser)).then((res: any) => {
      if (res.payload) {
        const needVerif = res.payload.NeedVerification;

        if (res.payload.Success) {
          if (needVerif) {
            openTwoFactorAuth();
            const dataSend = {
              idUser: res.payload.User.id,
              method: res.payload.User.defaultTwoFactorMethod,
              currentLanguage: currentLanguage ? currentLanguage : "FR"
            };
            dispatch(twoFactorAuthSendOtp(dataSend)).then((res: any) => {
              if (res.payload) {
                const decription = t(res.payload.Description);
                if (res.payload.Success) {
                  toast.success(decription, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(decription, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              }
            });
          } else {
            window.location.reload();
          }
        } else {
          openRegister();
          toast.error(res.payload.Description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    });
  };

  // GOOGLE
  const responseGoogle = async (googleUser: any) => {
    await dispatch(callbackGoogle(googleUser)).then((res: any) => {
      if (res?.payload) {
        const needVerif = res?.payload?.NeedVerification;

        if (res?.payload?.Success) {
          if (needVerif) {
            openTwoFactorAuth();
            const dataSend = {
              idUser: res?.payload?.User?.id,
              method: res?.payload?.User?.defaultTwoFactorMethod,
              currentLanguage: currentLanguage ? currentLanguage : "FR"
            };
            dispatch(twoFactorAuthSendOtp(dataSend)).then((res: any) => {
              if (res?.payload) {
                const description = t(res?.payload?.Description);
                if (res?.payload?.Success) {
                  toast.success(description, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(description, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              }
            });
          } else {
            handleClose();
          }
        } else {
          openRegister();
          toast.error(res?.payload?.Description, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    });
  };

  return (
    <>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Box sx={{ textAlign: "center" }}>
          <img width={100} src={Logo} alt="" />
        </Box>
      </BootstrapDialogTitle>
      <DialogContent
        dividers
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: ["0px 10px 30px 10px", "0px 75px 30px 75px"],
        }}
      >
        <div style={{ margin: "30px auto 0px auto" }}>
          <Typography
            sx={{
              textAlign: "center",
              color: "#484848",
              fontWeight: 500,
              fontSize: "24px",
              mb: 2,
              textTransform: "uppercase",
            }}
            variant="h3"
          >
            {t("se connecter")}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              textAlign: "center",
              color: "#8A8A8A",
              fontSize: "18px",
              lineHeight: "16px",
            }}
          >
            {t("Vous avez déja un compte ? Connectez-vous !")}
          </Typography>
        </div>
        <Box
          sx={{
            border: "thin solid #d9d9d9",
            borderRadius: "0.4rem",
            p: "0.5rem .9375rem 0px .9375rem",
          }}
        >
          <TextField
            id="idEmail"
            name="userEmail"
            type="email"
            onChange={handleChange}
            label={
              <span>
                {t("Adresse email")}
                <sup style={{ color: "red" }}> *</sup>
              </span>
            }
            placeholder="email@mail.com"
            fullWidth
            variant="standard"
            InputProps={{ disableUnderline: true, autoComplete: "email" }}
            InputLabelProps={{
              style: { color: "#8a8a8a", fontSize: "90%" },
            }}
            onKeyPress={handleKeyPress}
          />
        </Box>
        <Box
          sx={{
            border: "thin solid #d9d9d9",
            borderRadius: "0.4rem",
            p: "0.5rem .9375rem 0px .9375rem",
          }}
        >
          <TextField
            id="standard-password-input"
            name="password"
            onChange={handleChange}
            label={
              <span>
                {t("Mot de passe")} <sup style={{ color: "red" }}> *</sup>
              </span>
            }
            fullWidth
            placeholder={t("Mot de passe")}
            autoComplete="current-password"
            variant="standard"
            type={showPassword ? "text" : "password"}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    style={{
                      marginTop: "-22px",
                    }}
                  >
                    {showPassword ? <EyeOutline /> : <EyeOffOutline />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: { color: "#8a8a8a", fontSize: "90%" },
            }}
            onKeyPress={handleKeyPress}
          />
        </Box>
        {errorLogin !== "" && (
          <>
            <FormHelperText
              sx={{
                color: "red",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <InformationOutline sx={{ width: "20px" }} />
              <Typography sx={{ fontSize: "14px" }}>{errorLogin}</Typography>
            </FormHelperText>
          </>
        )}

        <CustomizedButton
          loading={loadingBtn}
          onClick={handleLogin}
          sx={{ ".css-62e83j-MuiCircularProgress-root": { color: "white" } }}
        >
          {t("se connecter")}
        </CustomizedButton>
        <Typography
          sx={{
            cursor: "pointer",
            fontWeight: 400,
            color: "#717BA1ff",
            fontSize: "16px",
            lineHeight: "16px",
            fontFamily: "Fira Sans, sans-serif",
            textAlign: "center",
            marginBottom: 2,
            "&:hover": {
              color: "#1b374c",
            },
          }}
          onClick={openForgotPsw}
        >
          {t("Mot de passe oublié")}
        </Typography>

        {/* IF FACEBOOK ID AND GOOGLE ID EXIST, SHOW THIS BOX */}
        {cliendId || facebookId ? (
          <Box sx={{ m: "auto" }}>
            <Typography
              color="secondary"
              variant="caption"
              sx={{
                textAlign: "center",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "16px",
              }}
            >
              {t("Ou se connecter avec")}
            </Typography>
            <Box
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: "row",
                gap: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {cliendId ? (
                <GoogleOAuthProvider clientId={cliendId}>
                  <GoogleLogin
                    onSuccess={responseGoogle}
                    onError={() => {
                      toast.error("Login Failed", {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                    }}
                    type="icon"
                    theme="outline"
                    shape="circle"
                  />
                </GoogleOAuthProvider>
              ) : null}
              {/* {facebookId ? (
                <FacebookLogin
                  appId={facebookId}
                  autoLoad={false}
                  callback={responseFacebook}
                  scope={"public_profile, email"}
                  language="fr-FR"
                  fields="name,email"
                  render={(renderProps: any) => (
                    <IconButton onClick={renderProps.onClick}>
                      <img
                        src={facebookIcon2}
                        alt="Facebook logo"
                        style={{ width: 34, height: 34 }}
                      />
                    </IconButton>
                  )}
                />
              ) : null} */}
            </Box>
          </Box>
        ) : null}

        <Typography
          variant="caption"
          sx={{
            textAlign: "center",
            color: "#8A8A8A",
            fontWeight: "400",
            fontSize: "16px",
            lineHeight: "16px",
          }}
        >
          {t("Vous n’avez pas de compte ? Créer-le !")}
        </Typography>
        <span
          onClick={openRegister}
          style={{
            cursor: "pointer",
            fontWeight: 500,
            fontFamily: "Fira Sans,sans-serif",
            color: "#F39325",
            textAlign: "center",
          }}
        >
          {t("s'inscrire")}
        </span>
      </DialogContent>
    </>
  );
};

export default Login;

const CustomizedButton = styled(LoadingButton)(({ theme }) => ({
  color: "white",
  backgroundColor: "#1b374c",
  fontFamily: "Fira Sans, sans-serif",
  padding: "1em 2em",
  margin: "10px 0",
  "&:hover": {
    backgroundColor: "#162c3d",
    color: "white",
  },
}));

export function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface ILoginProps {
  openForgotPsw?: any;
  openRegister?: any;
  handleClose?: any;
  openTwoFactorAuth?: any;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}
