export const mainPages = {
    home: {
        id: 'home',
        title: 'Home',
        path: '/',
        auth: false,
    },
    needHelp: {
        id: 'needHelp',
        title: 'Questions les plus fréquentes',
        path: '/besoin-daide',
        auth: false,
    },
    service: {
        id: 'maliciousService',
        title: 'Nos Services Malins',
        path: '/nos-services-malins',
        auth: false,
    },
    recrutment: {
        id: 'recrutement',
        title: 'Effia recrute',
        path: '/effia-recrute',
        auth: false,
    },
    about: {
        id: 'about',
        title: 'EFFIA, Déplacez-vous malin !',
        path: '/qui-sommes-nous/effia-deplacez-vous-malin',
        auth: false,
    },
    expertise: {
        id: 'expertise',
        title: 'Notre expertise sur voirie',
        path: '/qui-sommes-nous/notre-expertise-sur-voirie',
        auth: false,
    },
    legalMentions: {
        id: 'legalMentions',
        title: '',
        path: '/cgu-cgv-mentions-legales',
        auth: false,
    },
    roi: {
        id: 'roi',
        title: '',
        path: '/reglement-ordre-interieur',
        auth: false,
    },
    cityPage: {
        id: 'cityPage',
        title: '',
        path: '/ville/:city',
        auth: false,
    }
}

export const passwordModals = {
    resetPassword: {
        id: 'resetPassword',
        title: 'Reset password',
        path: '/reset-password/',
        auth: false,
    },
    passwordUpdate: {
        id: 'passwordUpdate',
        title: '',
        path: '/password-update/:token?',
        auth: false,
    },
}

export const reservationModals = {
    keyAccountArchivedReservations: {
        id: 'keyAccountArchivedReservations',
        title: '',
        path: '/key-account-archived-reservations',
        auth: true,
    },
    keyAccountCreateReservations: {
        id: 'keyAccouncreateReservations',
        title: '',
        path: '/key-account-create-reservations',
        auth: true,
    },
    keyAccountUpdateReservations: {
        id: 'keyAccounupdateReservations',
        title: '',
        path: '/key-account-update-reservations',
        auth: true,
    },
    keyAccountDetailsReservation: {
        id: 'keyAccounDetailsReservation',
        title: '',
        path: '/key-account-reservation-details',
        auth: true,
    },
    uclArchivedReservations: {
        id: 'uclArchivedReservations',
        title: '',
        path: '/ucl-archived-reservations',
        auth: true,
    },
}

export const parkingModals = {
    parkingService: {
        id: 'parkingService',
        title: 'Notre savoir-faire parking',
        path: '/qui-sommes-nous/notre-savoir-faire-parking',
        auth: false,
    },
    parkingReservation: {
        id: 'parkingReservation',
        title: 'Réservez votre place de parking',
        path: '/besoin-daide/reservez-votre-place-de-parking',
        auth: false,
    },
    parkingSubscription: {
        id: 'parkingSubscribtion',
        title: 'Souscrivez un abonnement',
        path: 'parking/:parkingName/search/',
        auth: false,
        noAccess: ["KeyAccount"],
    },
    parkingList: {
        id: 'parkingList',
        title: 'Liste parkings',
        path: 'parkings/search/:city',
        auth: false,
    },
    parkingReservationSub: {
        id: 'parkingReservationSub',
        title: '',
        path: '/reservez-votre-place-ou-souscrivez-un-abonnement-dans-le-parking-qui-vous-convient',
        auth: false,
    },
    reservationConfirmation: {
        id: 'reservationConfirmation',
        title: '',
        path: '/reservation-confirmation',
        auth: true,
        noAccess: ["KeyAccount"],
    },
}

export const subscriptionModals = {
    subscription: {
        id: 'subscription',
        title: 'Souscrivez un abonnement',
        path: '/besoin-daide/souscrivez-un-abonnement',
        auth: false,
    },
    subscriptionConfirmation: {
        id: 'subscriptionConfirmation',
        title: '',
        path: '/subscription-confirmation/',
        auth: true,
        noAccess: ["KeyAccount"],
    },
    keyAccountArchivedSubscriptions: {
        id: 'keyAccountArchivedSubscriptions',
        title: '',
        path: '/key-account-archived-subscriptions',
        auth: true,
    },
    keyAccouncreateSubscription: {
        id: 'keyAccouncreateSubscription',
        title: '',
        path: '/key-account-create-subscriptions',
        auth: true,
    },
    keyAccounUpdateSubscription: {
        id: 'keyAccounUpdateSubscription',
        title: '',
        path: '/key-account-update-subscription',
        auth: true,
    },
}
export const subscriptionPaymentModals = {
    subscriptionPayment: {
        id: 'subscriptionPayement',
        title: '',
        path: '/subscription-payement',
        auth: true,
        noAccess: ["KeyAccount"],
    },
    paymentRedirection: {
        id: 'payementRedirection',
        title: '',
        path: '/payment-return',
        auth: true,
    },
}

export const profileModals = {
    profile: {
        id: 'profile',
        title: '',
        path: '/profile',
        auth: true,
    },
    editProfile: {
        id: 'editProfile',
        title: '',
        path: '/edit-profile',
        auth: true,
    },
    verifyProfile: {
        id: 'verifyProfile',
        title: 'verify',
        path: '/verify-account/',
        auth: false,
    }
}

export const testPages = {
    loading: {
        id: 'loading',
        title: 'loading',
        path: 'loading',
        auth: false,
    }
}