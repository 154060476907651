import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// get All Parkings
export const getAllParkings = createAsyncThunk(
  "parking/getAllParkings",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get(`/getAllParking`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getParkings = createAsyncThunk(
  "parking/getParkings",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get(`/get-parkings-names`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get all user's parkings
// export const getParkingServices = createAsyncThunk(
//   "parking/getParkingServices",
//   async (parkingId: String, { rejectWithValue }) => {
//     try {
//       const result = await axios.get(`/get-services-parkingId/${parkingId}`, {
//         withCredentials: true,
//       });
//       return result.data;
//     } catch (error: any) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

// get parking by id
export const getParkingId = createAsyncThunk(
  "parking/getParkingId",
  async (parkingId: String | null, { rejectWithValue }) => {
    try {
      const result = await axios.get(`/get-parking-id/${parkingId}`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get the parking of the key accounts
export const getKeyAccountParkings = createAsyncThunk(
  "parking/get-key-account-parkings",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get(`/get-key-account-parkings`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getParkingSubscriptions = createAsyncThunk(
  "parking/get-parking-subscriptions",
  async (parkingId: String | null, { rejectWithValue }) => {
    try {
      const result = await axios.get(
        `/get-parking-subscriptions/${parkingId}`,
        {
          withCredentials: true,
        }
      );
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const parkingSlice = createSlice({
  name: "parking",
  initialState: {
    parking: {},
    parkings: [],
    subs: {},
    services: {},
    loading: false,
    description: null,
    errors: null,
    success: false,
    parkingSubscriptions: [],
  },
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // get all parkings
      .addCase(getAllParkings.pending, (state) => {
        state.parkings = [];
        state.loading = true;
        state.errors = null;
      })
      .addCase(getAllParkings.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.parkings = action.payload.data;
      })
      .addCase(getAllParkings.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.parkings = [];
      })
      // get all parkings
      .addCase(getParkings.pending, (state) => {
        state.parkings = [];
        state.loading = true;
        state.errors = null;
      })
      .addCase(getParkings.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.parkings = action.payload.Parkings;
      })
      .addCase(getParkings.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.parkings = [];
      })

      // get parking by id
      .addCase(getParkingId.pending, (state) => {
        state.parking = {};
        state.loading = true;
        state.errors = null;
      })
      .addCase(getParkingId.fulfilled, (state, action) => {
        state.parking = action.payload.Parking;
        state.loading = false;
        state.errors = null;
      })
      .addCase(getParkingId.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.parking = {};
      })

      // get parking services
      // .addCase(getParkingServices.pending, (state) => {
      //   state.loading = true;
      //   state.errors = null;
      // })
      // .addCase(getParkingServices.fulfilled, (state, action) => {
      //   state.services = action.payload.data;
      //   state.loading = false;
      //   state.errors = null;
      // })
      // .addCase(getParkingServices.rejected, (state: any, action: any) => {
      //   state.loading = false;
      //   state.errors = action.payload.Description;
      //   state.services = {};
      // })
      // get current key account parkings
      .addCase(getKeyAccountParkings.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.description = null;
        state.parkings = [];
        state.success = false;
      })
      .addCase(getKeyAccountParkings.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.description = action.payload.Description;
        state.parkings = action.payload.Parkings;
        state.success = action.payload.Success;
      })
      .addCase(getKeyAccountParkings.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.parkings = [];
        state.description = action.payload.Description;
        state.success = action.payload.Success;
      })
      // subscription list of the selected parking by the key account
      .addCase(getParkingSubscriptions.pending, (state) => {
        state.loading = true;
        state.errors = null;
        state.description = null;
        state.parkingSubscriptions = [];
        state.success = false;
      })
      .addCase(getParkingSubscriptions.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.description = action.payload.Description;
        state.parkingSubscriptions = action.payload.ParkingSubscriptions;
        state.success = action.payload.Success;
      })
      .addCase(getParkingSubscriptions.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.parkingSubscriptions = [];
        state.description = action.payload.Description;
        state.success = action.payload.Success;
      });
  },
});

export default parkingSlice.reducer;
