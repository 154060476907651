import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

//all cites
export const getAllCities = createAsyncThunk(
  "site/getAllCities",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get(`get-all-cities`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//parkings by city
export const getParkingsByCity = createAsyncThunk(
  "parking/getParkingsByCity",
  async (title: any, { rejectWithValue }) => {
    try {
      const result = await axios.get(`/get-parkings-by-city/${title}`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//parkings by city
export const getCityByID = createAsyncThunk(
  "parking/getCityByID",
  async (cityID: any, { rejectWithValue }) => {
    try {
      const result = await axios.get(`get-city-id/${cityID}`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//parkings by city
export const getCityByName = createAsyncThunk(
  "parking/getCityByName",
  async (name: any, { rejectWithValue }) => {
    try {
      const result = await axios.get(`get-city-name/${name}`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//get price of sub by park in cite
export const getPriceSubofParkCity = createAsyncThunk(
  "parking/getSubPriceBycity",
  async (cityID: any, { rejectWithValue }) => {
    try {
      const result = await axios.get(`get-subPrice-city/${cityID}`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//get price of res by park in cite
export const calculatePriceReservationofParkCity = createAsyncThunk(
  "parking/calculateReservationPriceBycity",
  async ({ cityID, dateRes }: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        `calculate-reservationPrice-city/${cityID}`,
        dateRes,
        {
          withCredentials: true,
        }
      );
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const citySlice = createSlice({
  name: "city",
  initialState: {
    city: {},
    cities: [],
    subscriptionPrices: [],
    reservationPrices: [],
    loading: false,
    success: false,
    errors: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    builder

      // get all parkings
      .addCase(getAllCities.pending, (state) => {
        state.cities = [];
        state.loading = true;
        state.errors = null;
      })
      .addCase(getAllCities.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.errors = null;
        state.cities = action.payload.Cities;
      })
      .addCase(getAllCities.rejected, (state: any, action: any) => {
        state.loading = false;
        state.success = false;
        state.errors = action.payload.Description;
        state.cites = [];
      })
      // get all parkings by site
      .addCase(getParkingsByCity.pending, (state) => {
        state.city = {};
        state.loading = true;
        state.errors = null;
      })
      .addCase(getParkingsByCity.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.errors = null;
        state.city = action.payload.Parkings;
      })
      .addCase(getParkingsByCity.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.city = {};
        state.success = false;
      })
      //getCityByID
      .addCase(getCityByID.pending, (state) => {
        state.city = {};
        state.loading = true;
        state.errors = null;
      })
      .addCase(getCityByID.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.errors = null;
        state.city = action.payload.City;
      })
      .addCase(getCityByID.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.city = {};
        state.success = false;
      })
      //getCityByName
      .addCase(getCityByName.pending, (state) => {
        state.city = {};
        state.loading = true;
        state.errors = null;
      })
      .addCase(getCityByName.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.errors = null;
        state.city = action.payload.City;
      })
      .addCase(getCityByName.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.city = {};
        state.success = false;
      })
      //getPriceSubofParkCity
      .addCase(getPriceSubofParkCity.pending, (state) => {
        state.subscriptionPrices = [];
        state.loading = true;
        state.errors = null;
      })
      .addCase(getPriceSubofParkCity.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.errors = null;
        state.subscriptionPrices = action.payload.SubscriptionPrices;
      })
      .addCase(getPriceSubofParkCity.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.subscriptionPrices = [];
        state.success = false;
      })
      //calculatePriceReservationofParkCity
      .addCase(calculatePriceReservationofParkCity.pending, (state) => {
        state.reservationPrices = [];
        state.loading = true;
        state.errors = null;
      })
      .addCase(
        calculatePriceReservationofParkCity.fulfilled,
        (state, action) => {
          state.loading = false;
          state.success = true;
          state.errors = null;
          state.reservationPrices = action.payload.ReservationPrices;
        }
      )
      .addCase(
        calculatePriceReservationofParkCity.rejected,
        (state: any, action: any) => {
          state.loading = false;
          state.errors = action.payload.Description;
          state.reservationPrices = [];
          state.success = false;
        }
      );
  },
});

export default citySlice.reducer;
