import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import axios from "axios";
import axios from "axios";

//** GET USER CARDS
export const getUserCard = createAsyncThunk(
  "user/getUserCard",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get("get-user-cards", {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//** ADD NEW USER CARD
// const {name, number, expiry, cvc, cardType, subscriptionId, defaultCard} = req.body;
export const addUserCard = createAsyncThunk(
  "user/addUserCard",
  async (data: object, { rejectWithValue }) => {
    try {
      const result = await axios.post("create-user-payment-token", data, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//** DELETE USER CARD  
// const {id} = req.body;
export const deleteUserCard = createAsyncThunk(
  "user/deleteUserCard",
  async (id: string, { rejectWithValue }) => {
    try {
      const result = await axios.post("delete-user-payment-token", {id}, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const cardSlice = createSlice({
  name: "card",
  initialState: {
    success: false,
    description: null,
    cards: [],
  },
  reducers: {
    clearErrors: (state) => {
      state.description = null;
    },
  },
  extraReducers: (builder) => {
    builder
      //** getUserCard
      .addCase(getUserCard.pending, (state) => {
        state.success = false;
        state.description = null;
        state.cards = [];
      })
      .addCase(getUserCard.fulfilled, (state, action) => {
        state.success = true;
        state.description = null;
        state.cards = action.payload.Cards;
      })
      .addCase(getUserCard.rejected, (state: any, action: any) => {
        state.success = false;
        state.description = action.payload.Description;
        state.cards = [];
      })
      //** addUserCard
      .addCase(addUserCard.pending, (state) => {
        state.success = false;
        state.description = null;
        state.cards = [];
      })
      .addCase(addUserCard.fulfilled, (state, action) => {
        state.success = true;
        state.description = action.payload.Description;
        state.cards = action.payload.Cards;
      })
      .addCase(addUserCard.rejected, (state: any, action: any) => {
        state.success = false;
        state.description = action.payload.Description;
        state.cards = [];
      })
      //** deleteUserCard
      .addCase(deleteUserCard.pending, (state) => {
        state.success = false;
        state.description = null;
      })
      .addCase(deleteUserCard.fulfilled, (state, action) => {
        state.success = true;
        state.description = action.payload.Description;
        state.cards = action.payload.Cards;
      })
      .addCase(deleteUserCard.rejected, (state: any, action: any) => {
        state.success = false;
        state.description = action.payload.Description;
      });
  },
});

export default cardSlice.reducer;
