// ** React imports
import React, {
  useLayoutEffect,
  useEffect,
  useState,
  useContext,
  Suspense,
} from "react";

// ** Router imports
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

// ** Third party imports
import { toast, ToastContainer } from "react-toastify";
import { BroadcastChannel } from "broadcast-channel";
import "react-toastify/dist/ReactToastify.css";
import { io } from "socket.io-client";
import { useTranslation } from "react-i18next";

// ** Components imports
import NavbarEffia from "./components/navbar/navbarEffia";
import Footer from "./components/footer";

// ** Function imports
import { generateRoute } from "./routes";
import { logoutAllTabs } from "./utils/getToken";
import AuthContext from "./context/authContext";

// ** Redux imports
import { useSelector } from "react-redux";
import { getNotifications, verifyAccount } from "./redux/auth/authSlice";
import { useAppDispatch } from "./redux/hooks";

// ** MUI imports
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import LinearIndeterminate from "./pages/Loading";
import Page404 from "./pages/Page404";
import { checkPaymentStatus } from "./redux/payment/paymentSlice";
import { socket } from "./socket";

const logoutChannel = new BroadcastChannel("logout");

function App() {
  const location = useLocation();
  const userReducer = useSelector((state: any) => state?.auth?.user);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const tokenVerificationAccount = searchParams.get("accountverification");
  const hostedCheckoutId = searchParams.get("hostedCheckoutId");

  useEffect(() => {
    const fetch = async () => {
      await dispatch(verifyAccount(tokenVerificationAccount));
    };
    if (tokenVerificationAccount) {
      fetch();
    }
  }, [tokenVerificationAccount]);

  // ** Translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language.toUpperCase();

  // ** states
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);

  // ** Scroll to the top of the page
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const getNotif = async () => {
    await dispatch(getNotifications());
  };

  logoutChannel.onmessage = (msg) => console.log(msg);

  useEffect(() => {
    logoutAllTabs();
  }, []);

  // ** Pass userType to routes for role management
  const { userType } = useContext(AuthContext);
  const renderedRoutes = generateRoute(userType);

  // useEffect(() => {
  //   if (Object.keys(userReducer).length === 0 && isToken) {
  //     deleteTokenUser();
  //   }
  // }, [userReducer, isToken]);

  useEffect(() => {
    if (userReducer && Object.keys(userReducer).length > 0 && socket) {
      socket.connect();
      socket.emit("setup", userReducer.id);

      // Listen for notifications
      const handleNotification = (result: any) => {
        setShowSnackbar(true);
        getNotif();
      };

      // Get notifications
      socket.on("send-notification", handleNotification);

      return () => {
        socket.off("send-notification", handleNotification);
        socket.disconnect();
      };
    }
  }, [userReducer, socket]);

  useEffect(() => {
    const fetch = async () => {
      const data = {
        hostedCheckoutID: hostedCheckoutId,
        currentLanguage: currentLanguage,
      };
      const checkStatus = await dispatch(checkPaymentStatus(data));
      if (checkStatus && checkStatus?.payload) {
        const description = t(checkStatus?.payload?.Description);
        if (checkStatus?.payload?.Success) {
          toast.success(description, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          if (description) {
            toast.error(description, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
        //** redirect user */
        if (checkStatus?.payload?.IsSub) {
          navigate(`/profile`, {
            state: { value: 2 },
          });
        } else {
          navigate(`/profile`, {
            state: { value: 1 },
          });
        }
      }
    };
    if (hostedCheckoutId) {
      fetch();
    }
  }, [hostedCheckoutId]);

  return (
    <div
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <NavbarEffia />
      <div style={{ paddingTop: "5rem", flex: 1 }}>
        <Suspense fallback={<LinearIndeterminate />}>
          <Routes>
            <Route path="/">{renderedRoutes}</Route>
            <Route path="*" element={<Page404 />} />
          </Routes>
        </Suspense>
      </div>
      <div style={{ width: "100%", paddingTop: "2rem" }}>
        <Footer />
      </div>
      <ToastContainer />
      {/* snackbar */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={4000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert
          onClose={() => setShowSnackbar(false)}
          severity="info"
          sx={{ backgroundColor: "rgb(2, 136, 209)", color: "white" }}
        >
          {t("Vous avez reçu une nouvelle notification")}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
