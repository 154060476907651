const checkPasswordStrength = (password: string) => {
  //password specification
  // const veryStrongRegex =
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()\-__+.]).{8,}$/;
  // const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/;
  // const weakRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  // const veryWeakRegex = /^(?=.*[a-z]).{8,}$/;
  // const veryVeryWeakRegex = /^.{8,}$/;

  // Define regex patterns for each criteria
  const hasLowercase = /[a-z]/.test(password);
  const hasUppercase = /[A-Z]/.test(password);
  const hasDigit = /[0-9]/.test(password);
  const hasSpecialCharacter = /[!@#$%^&*()\-__+.]/.test(password);
  const isLongEnough = password.length >= 8;

  if (isLongEnough) {
    if (hasLowercase && hasUppercase && hasDigit && hasSpecialCharacter) {
      // Very strong password
      return {
        result: {
          fr: "Très fort",
          en: "Very strong",
          nl: "Zeer sterk",
        },
        value: 100,
        color: "#008000",
        msg: {
          fr: "Votre mot de passe est très fort",
          en: "Your password is very strong",
          nl: "Uw wachtwoord is erg sterk",
        },
        confirm: true,
      };
    } else if (hasLowercase && hasUppercase && hasDigit) {
      // Strong password
      return {
        result: {
          fr: "Fort",
          en: "Strong",
          nl: "Sterk",
        },
        value: 70,
        color: "#32CD32",
        msg: {
          fr: "Le mot de passe doit contenir au minimum un caractère spécial",
          en: "The password must contain at least one special character",
          nl: "Het wachtwoord moet minstens één speciaal karakter bevatten",
        },
        confirm: false,
      };
    } else if (hasLowercase && hasUppercase) {
      // Medium password
      return {
        result: {
          fr: "Moyen",
          en: "Medium",
          nl: "Gemiddeld",
        },
        value: 55,
        color: "#FFD700",
        msg: {
          fr: "Le mot de passe doit contenir au minimum un chiffre",
          en: "The password must contain at least one digit",
          nl: "Het wachtwoord moet minstens één cijfer bevatten",
        },
        confirm: false,
      };
    } else if (hasLowercase) {
      // Weak password
      return {
        result: {
          fr: "Faible",
          en: "Weak",
          nl: "Zwak",
        },
        value: 35,
        color: "#FFA500",
        msg: {
          fr: "Le mot de passe doit contenir au minimum une lettre majuscule",
          en: "The password must contain at least one uppercase letter",
          nl: "Het wachtwoord moet minstens één hoofdletter bevatten",
        },
        confirm: false,
      };
    } else {
      // Very weak password
      return {
        result: {
          fr: "Très faible",
          en: "Very weak",
          nl: "Zeer zwak",
        },
        value: 20,
        color: "#FF4500",
        msg: {
          fr: "Le mot de passe doit contenir au minimum une lettre minuscule",
          en: "The password must contain at least one lowercase letter",
          nl: "Het wachtwoord moet minstens één kleine letter bevatten",
        },
        confirm: false,
      };
    }
  } else {
    // Password is too short
    return {
      result: {
        fr: "Trop court",
        en: "Too short",
        nl: "Te kort",
      },
      value: 5,
      color: "#FF0000",
      msg: {
        fr: "Le mot de passe doit contenir au minimum 8 caractères",
        en: "The password must be at least 8 characters long",
        nl: "Het wachtwoord moet minstens 8 tekens lang zijn",
      },
      confirm: false,
    };
  }
};

export default checkPasswordStrength;
