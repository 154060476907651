import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// create new logicalEntity
export const getLogicalEntities = createAsyncThunk(
  "logicalEntity/getLogicalEntities",
  async (entities: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(`get-entities`, entities);
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getLogicalEntitiesParkings = createAsyncThunk(
  "logicalEntity/getLogicalEntitiesParkings",
  async (entities: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(`get-parking-entities`, entities);
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
const logicalEntity = createSlice({
  name: "logicalEntity",
  initialState: {
    logicalEntity: {},
    LogicalEntities: [],
    loading: false,
    description: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.description = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // create new logicalEntity
      .addCase(getLogicalEntities.pending, (state) => {
        state.LogicalEntities = [];
        state.loading = true;
        state.description = null;
      })
      .addCase(getLogicalEntities.fulfilled, (state, action) => {
        state.loading = false;
        state.description = null;
        state.LogicalEntities = action.payload.LogicalEntities;
        state.description = action.payload.Description;
      })
      .addCase(getLogicalEntities.rejected, (state: any, action: any) => {
        state.loading = false;
        state.description = action.payload.Description;
        state.LogicalEntities = [];
      })
      //
      .addCase(getLogicalEntitiesParkings.pending, (state) => {
        state.LogicalEntities = [];
        state.loading = true;
        state.description = null;
      })
      .addCase(getLogicalEntitiesParkings.fulfilled, (state, action) => {
        state.loading = false;
        state.description = null;
        state.LogicalEntities = action.payload.LogicalEntities;
        state.description = action.payload.Description;
      })
      .addCase(
        getLogicalEntitiesParkings.rejected,
        (state: any, action: any) => {
          state.loading = false;
          state.description = action.payload.Description;
          state.LogicalEntities = [];
        }
      );
  },
});

export default logicalEntity.reducer;
