import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import axios from "axios";

// create new driver
export const getDriverById = createAsyncThunk(
  "driver/getDriverById",
  async (driverID: any, { rejectWithValue }) => {
    try {
      const result = await axios.get(`get-driver/new-driver/${driverID}`, {
        withCredentials: true,
      });

      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get all user's drivers
export const getDrivers = createAsyncThunk(
  "driver/getDrivers",
  async (_, { rejectWithValue }) => {
    //
    try {
      const result = await axios.get(`get-user-drivers`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get all user's driver
export const createDriver = createAsyncThunk(
  "driver/createDriver",
  async (driverInfo: Object, { rejectWithValue }) => {
    try {
      const result = await axios.post(`create-driver`, driverInfo, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// update driver
export const updateDriver = createAsyncThunk(
  "driver/updateDriver",
  async (driverInfo: any, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        `edit-user-driver/${driverInfo.driverID}`,
        driverInfo,
        {
          withCredentials: true,
        }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// create new driver
export const deleteDriver = createAsyncThunk(
  "driver/deleteDriver",
  async (driverID: String, { rejectWithValue }) => {
    try {
      const result = await axios.put(`/delete-driver/${driverID}`, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);
const driver = createSlice({
  name: "driver",
  initialState: {
    driver: {},
    drivers: [],
    loading: false,
    description: null,
    success: false,
  },
  reducers: {
    clearErrors: (state) => {
      state.description = null;
    },
  },
  extraReducers: (builder) => {
    builder

      // create new driver
      .addCase(getDriverById.pending, (state) => {
        state.driver = {};
        state.loading = true;
        state.description = null;
        state.success = false;
      })
      .addCase(getDriverById.fulfilled, (state, action) => {
        state.loading = false;
        state.description = action.payload.Description;
        state.driver = action.payload.Driver;
        state.success = true;
      })
      .addCase(getDriverById.rejected, (state: any, action: any) => {
        state.loading = false;
        state.description = action.payload.Description;
        state.driver = {};
        state.success = false;
      })
      // get all user's driver
      .addCase(getDrivers.pending, (state) => {
        state.drivers = [];
        state.loading = true;
        state.description = null;
        state.success = false;
      })
      .addCase(getDrivers.fulfilled, (state, action) => {
        state.drivers = action.payload.Drivers;
        state.loading = false;
        state.description = null;
        state.success = true;
      })
      .addCase(getDrivers.rejected, (state: any, action: any) => {
        state.loading = false;
        state.description = action.payload.Description;
        state.drivers = [];
        state.success = false;
      })
      // create new driver
      .addCase(createDriver.pending, (state) => {
        state.driver = {};
        state.loading = true;
        state.description = null;
        state.success = false;
      })
      .addCase(createDriver.fulfilled, (state, action) => {
        state.drivers = action.payload.Drivers;
        state.loading = false;
        state.description = null;
        state.success = true;
      })
      .addCase(createDriver.rejected, (state: any, action: any) => {
        state.loading = false;
        state.description = action.payload.Description;
        state.driver = {};
        state.success = false;
      })
      // edit driver
      .addCase(updateDriver.pending, (state) => {
        state.driver = {};
        state.loading = true;
        state.description = null;
        state.success = false;
      })
      .addCase(updateDriver.fulfilled, (state, action) => {
        state.loading = false;
        state.description = action.payload.Description;
        state.drivers = action.payload.Drivers;
        state.success = true;
      })
      .addCase(updateDriver.rejected, (state: any, action: any) => {
        state.loading = false;
        state.description = action.payload.Description;
        state.success = false;
      })
      // delete driver
      .addCase(deleteDriver.pending, (state) => {
        state.loading = true;
        state.description = null;
        state.success = false;
      })
      .addCase(deleteDriver.fulfilled, (state, action) => {
        state.loading = false;
        state.description = action.payload.Description;
        state.drivers = action.payload.Drivers;
        state.success = true;
      })
      .addCase(deleteDriver.rejected, (state: any, action: any) => {
        state.loading = false;
        state.description = action.payload.Description;
        state.driver = {};
        state.success = false;
      });
  },
});

export default driver.reducer;
