import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

// calculate the price
export const calculeReservationTarif = createAsyncThunk(
  "tarif/calculeReservationTarif",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(`/calculate-price`, data);

      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);
// calculate tarif for temporary subscription

export const calculateAbonTarif = createAsyncThunk(
  "tarif/calculeTemporaryTarif",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        `/calculate-subscription-temporelle-price`,
        data
      );

      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const calculateCustomSubTarif = createAsyncThunk(
  "tarif/calculeCustomizableTarif",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        `/calculate-subscription-customizable-price`,
        data
      );

      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const calculeTarifForfaitaire = createAsyncThunk(
  "tarif/calculeTemporaryTarif",
  async (data: any, { rejectWithValue }) => {
    try {
      const result = await axios.post(
        `/calculate-subscription-forfaitaire-price`,
        data
      );
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const tarifSlice = createSlice({
  name: "tarif",
  initialState: {
    tarif: 0,
    loading: false,
    errors: null,
    success: false,
    data: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // calculate the price
      .addCase(calculeReservationTarif.pending, (state) => {
        state.tarif = 0;
        state.loading = true;
        state.errors = null;
      })
      .addCase(calculeReservationTarif.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.tarif = action.payload.Tarif;
        state.success = true;
      })
      .addCase(calculeReservationTarif.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.tarif = 0;
        state.success = false;
      })
      //calculate the subscription temporel price

      .addCase(calculateAbonTarif.pending, (state) => {
        state.tarif = 0;
        state.loading = true;
        state.errors = null;
      })
      .addCase(calculateAbonTarif.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.tarif = action.payload.Tarif;
        state.success = true;
        state.data = action.payload.Data;
      })
      .addCase(calculateAbonTarif.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.tarif = 0;
        state.success = false;
      })
      // // //calculate the subscription prepaid price

      // .addCase(calculeTarifForfaitaire.pending, (state) => {
      //   state.tarif = 0;
      //   state.loading = true;
      //   state.errors = null;
      // })
      // .addCase(calculeTarifForfaitaire.fulfilled, (state, action) => {
      //   state.loading = false;
      //   state.errors = null;
      //   state.tarif = action.payload.Tarif;
      // })
      // .addCase(calculeTarifForfaitaire.rejected, (state: any, action: any) => {
      //   state.loading = false;
      //   state.errors = action.payload.Description;
      //   state.tarif = 0;
      // })
      // // //calculate the subscription customizable price

      .addCase(calculateCustomSubTarif.pending, (state) => {
        state.tarif = 0;
        state.loading = true;
        state.errors = null;
      })
      .addCase(calculateCustomSubTarif.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.tarif = action.payload.Tarif;
        state.data = action.payload.Data;
      })
      .addCase(calculateCustomSubTarif.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.tarif = 0;
      });
  },
});

export default tarifSlice.reducer;
