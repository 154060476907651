import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

// create new vehicle
export const newVehicle = createAsyncThunk(
  "vehicle/newVehicle",
  async (vehicleInfo: Object, { rejectWithValue }) => {
    try {
      const result = await axios.post(`create-vehicle`, vehicleInfo, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

// get all user's vehicles
export const getVehicles = createAsyncThunk(
  "vehicle/getVehicles",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get(`get-user-vehicles`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get all user's cars
export const getCars = createAsyncThunk(
  "vehicle/getCars",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get(`get-user-cars`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get all user's bicycles
export const getBicycles = createAsyncThunk(
  "vehicle/getBicycles",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get(`get-user-bicycles`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// get all user's motos
export const getMotos = createAsyncThunk(
  "vehicle/getMotos",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get(`get-user-motos`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
// get vehicle by id
export const getVehicleById = createAsyncThunk(
  "vehicle/getVehicleById",
  async (vehicleID: String | undefined, { rejectWithValue }) => {
    try {
      const result = await axios.get(`get-vehicle/${vehicleID}`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// update vehicle
export const updateVehicle = createAsyncThunk(
  "vehicle/updateVehicle",
  async (updateInfo: any, { rejectWithValue }) => {
    try {
      const result = await axios.put(
        `edit-vehicle/${updateInfo.vehicleID}`,
        updateInfo,
        { withCredentials: true }
      );
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

//  delete vehicle
export const deleteVehicle = createAsyncThunk(
  "vehicle/deleteVehicle",
  async (vehicleID: String | undefined, { rejectWithValue }) => {
    try {
      const result = await axios.put(`delete-vehicle/${vehicleID}`, {
        withCredentials: true,
      });
      if (result.data.Success) {
        toast.success(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error(result.data.Description, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return result.data;
    } catch (error: any) {
      toast.error(error.response.data.Description, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(error.response.data);
    }
  }
);

const vehicleSlice = createSlice({
  name: "vehicle",
  initialState: {
    vehicle: {},
    vehicles: [],
    loading: false,
    description: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.description = null;
    },
  },
  extraReducers: (builder) => {
    builder

      // create new vehicle
      .addCase(newVehicle.pending, (state) => {
        state.vehicle = {};
        state.loading = true;
        state.description = null;
      })
      .addCase(newVehicle.fulfilled, (state, action) => {
        state.loading = false;
        state.description = null;
        state.vehicle = action.payload.Vehicle;
        state.vehicles = action.payload.Vehicles;
        state.description = action.payload.Description;
      })
      .addCase(newVehicle.rejected, (state: any, action: any) => {
        state.loading = false;
        state.description = action.payload.Description;
        state.vehicle = {};
      })
      // get all user's vehicle
      .addCase(getVehicles.pending, (state) => {
        state.vehicles = [];
        state.loading = true;
        state.description = null;
      })
      .addCase(getVehicles.fulfilled, (state, action) => {
        state.loading = false;
        state.description = null;
        state.vehicles = action.payload.Vehicles;
        state.description = action.payload.Description;
      })
      .addCase(getVehicles.rejected, (state: any, action: any) => {
        state.loading = false;
        state.description = action.payload.Description;
        state.vehicles = [];
      })
      // get vehicle by id
      .addCase(getVehicleById.pending, (state) => {
        state.vehicle = {};
        state.loading = true;
        state.description = null;
      })
      .addCase(getVehicleById.fulfilled, (state, action) => {
        state.loading = false;
        state.description = null;
        state.vehicle = action.payload.Vehicle;
        state.description = action.payload.Description;
      })
      .addCase(getVehicleById.rejected, (state: any, action: any) => {
        state.loading = false;
        state.description = action.payload.Description;
        state.vehicle = {};
      })
      // edit vehicle
      .addCase(updateVehicle.pending, (state) => {
        state.vehicle = {};
        state.loading = true;
        state.description = null;
      })
      .addCase(updateVehicle.fulfilled, (state, action) => {
        state.loading = false;
        state.description = null;
        state.vehicles = action.payload.Vehicles;
        state.description = action.payload.Description;
      })
      .addCase(updateVehicle.rejected, (state: any, action: any) => {
        state.loading = false;
        state.description = action.payload.Description;
      })
      // delete vehicle
      .addCase(deleteVehicle.pending, (state) => {
        state.vehicle = {};
        state.loading = true;
        state.description = null;
      })
      .addCase(deleteVehicle.fulfilled, (state, action) => {
        state.loading = false;
        state.description = null;
        state.vehicles = action.payload.Vehicles;
        state.description = action.payload.Description;
      })
      .addCase(deleteVehicle.rejected, (state: any, action: any) => {
        state.loading = false;
        state.description = action.payload.Description;
      })
      // get all user's vehicle
      .addCase(getCars.pending, (state) => {
        state.vehicles = [];
        state.loading = true;
        state.description = null;
      })
      .addCase(getCars.fulfilled, (state, action) => {
        state.loading = false;
        state.description = null;
        state.vehicles = action.payload.Vehicles;
        state.description = action.payload.Description;
      })
      .addCase(getCars.rejected, (state: any, action: any) => {
        state.loading = false;
        state.description = action.payload.Description;
        state.vehicles = [];
      })
      // get all user's vehicle
      .addCase(getBicycles.pending, (state) => {
        state.vehicles = [];
        state.loading = true;
        state.description = null;
      })
      .addCase(getBicycles.fulfilled, (state, action) => {
        state.loading = false;
        state.description = null;
        state.vehicles = action.payload.Vehicles;
        state.description = action.payload.Description;
      })
      .addCase(getBicycles.rejected, (state: any, action: any) => {
        state.loading = false;
        state.description = action.payload.Description;
        state.vehicles = [];
      })
      // get all user's vehicle
      .addCase(getMotos.pending, (state) => {
        state.vehicles = [];
        state.loading = true;
        state.description = null;
      })
      .addCase(getMotos.fulfilled, (state, action) => {
        state.loading = false;
        state.description = null;
        state.vehicles = action.payload.Vehicles;
        state.description = action.payload.Description;
      })
      .addCase(getMotos.rejected, (state: any, action: any) => {
        state.loading = false;
        state.description = action.payload.Description;
        state.vehicles = [];
      });
  },
});

export default vehicleSlice.reducer;
