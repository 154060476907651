import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import userReducer from "./user/userSlice";
import parkingReducer from "./parking/parkingSlice";
import driverReducer from "./driver/driver";
import vehicleReducer from "./vehicle/vehicleSlice";
import subscriptionReducer from "./subscription/subscriptionSlice";
import reservationReducer from "./reservation/reservationSlice";
import ticketCategoriesReducer from "./categoryReclamation/categoryReclmation";
import KeyAccountReservationReducer from "./keyAccount/keyAccountSlice";
import paymentReducer from "./payment/paymentSlice";
import tarifReducer from "./tarif/tarifSlice";
import serviceReducer from "./services/serviceSlice";
import reducerReducer from "./city/citySlice";
import logicalEntityReucer from "./logicalEntity/logicalEntitySlice";
import keyAccountSubscriptionReducer from "./keyAccountSubscription/keyAccountSubscriptionSlice";
import reclamationReducer from "./reclamation/reclamation";
import cardSlice from "./card/cardSlice";

// Augment middleware to consider Immutable.JS iterables serializable
export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    parking: parkingReducer,
    driver: driverReducer,
    vehicle: vehicleReducer,
    subscription: subscriptionReducer,
    reservation: reservationReducer,
    ticketCategories: ticketCategoriesReducer,
    keyAccountReservation: KeyAccountReservationReducer,
    worldlinePayment: paymentReducer,
    tarif: tarifReducer,
    service: serviceReducer,
    city: reducerReducer,
    logicalEntity: logicalEntityReucer,
    keyAccountSubscription: keyAccountSubscriptionReducer,
    reclamation: reclamationReducer,
    card: cardSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
