// import { pagesData } from "./routes";
import {contents} from './contentRoutes'
import { RouteType } from "./routeTypes";
import { GetToken } from "../utils/getToken";
import { Route, Navigate } from "react-router-dom";

const allowAccessToAllPaths = (
  routes: RouteType[],
  isAuth: boolean,
  userType: any
): RouteType[] => {
  return routes;
};

export const generateRoute = (userType: any) => {
  const isUserAuthenticated = GetToken();

  return allowAccessToAllPaths(contents, isUserAuthenticated, userType).map(
    (route: RouteType, index: number) => {
      if (route.auth && !isUserAuthenticated) {
        return (
          <Route
            path={route.path}
            element={
              <Navigate to="/" replace state={{ fromUnauthorizedPage: true }} />
            }
            key={index}
          />
        );
      }

      if (route.noAccess && route.noAccess.includes(userType)) {
        return (
          <Route
            path={route.path}
            element={
              <Navigate to="/" replace state={{ fromUnauthorizedPage: true }} />
            }
            key={index}
          />
        );
      }

      return (
        <Route
          path={route.path}
          element={<div>{route.element}</div>}
          key={index}
        />
      );
    }
  );
};
