import { Box } from "@mui/system";
import React, { FC } from "react";

interface Props {
  children: React.ReactNode;
}

const StaticPagesLayout: FC<Props> = (props: Props) => {
  return <Box>{props.children}</Box>;
};

export default StaticPagesLayout;
