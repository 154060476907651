import React, { FC, useEffect, useState } from "react";

// ** Router imports
import { useLocation, useNavigate } from "react-router-dom";

// ** MUI imports
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

// ** Redux imports
import { useAppDispatch } from "../../redux/hooks";
import { useSelector } from "react-redux";
import { getAllCities } from "../../redux/city/citySlice";

// ** Icons imports
import Magnify from "mdi-material-ui/Magnify";

// ** Third party imports
import { useTranslation } from "react-i18next";
import { Button, Dialog } from "@mui/material";
import { CustomDatePickerNoBorder } from "../datePicker";
import moment from "moment";

const SearchParkingAutocomplete: FC<any> = ({ getSelectedSite }) => {
  //** Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language.toUpperCase();
  const cityReducer = useSelector((state: any) => state.city);

  // ** States
  const [value, setValue] = React.useState<any>();
  const [selectedSiteId, setSelectedSiteId] = React.useState<string | null>("");

  // ** Get selectedSiteID callback function
  useEffect(() => {
    getSelectedSite(selectedSiteId);
    //eslint-disable-next-line
  }, [selectedSiteId]);

  // **
  const getTranslatedValue = (value: any) => {
    if (value && typeof value === "object") {
      return value[currentLanguage] || value["FR"];
    }
    return value;
  };

  // ** Navigate to the selected site page
  const handleNavigate = (city: any) => {
    if (city) {
      const siteName = getTranslatedValue(city?.siteName)?.toLowerCase();
      const siteID = city.id;

      const navigationPath =
        siteID === "f2b6d97a-9082-4352-9436-5aedb1c8abe7"
          ? `/ville/${siteName}`
          : `/parkings/search/${siteName}`;

      navigate(navigationPath, {
        state: { cityID: siteID },
      });

      // Empty the selectedSite after navigation
      setValue(null);
    }
  };

  const handleOptionSelect = (event: any, option: any) => {
    if (option) {
      const value = getTranslatedValue(option?.value)?.toUpperCase();
      const selectedCity = cityReducer?.cities?.find((site: any) => {
        const siteName = getTranslatedValue(site?.siteName)?.toUpperCase();
        return siteName === value;
      });
      if (selectedCity) {
        setSelectedSiteId(selectedCity);
      }
    }
  };

  const sortedCities = [...(cityReducer?.cities || [])].sort(
    (a: any, b: any) => {
      const nameA = getTranslatedValue(a?.siteName).toUpperCase();
      const nameB = getTranslatedValue(b?.siteName).toUpperCase();
      return nameA.localeCompare(nameB);
    }
  );

  return (
    <Autocomplete
      id="free-solo-demo"
      freeSolo
      options={sortedCities?.map((option: any) => ({
        label: getTranslatedValue(option?.siteName)?.toUpperCase(),
        value: getTranslatedValue(option?.siteName)?.toUpperCase(),
      }))}
      sx={{
        bgcolor: "white",
        borderRadius: "0.4rem",
        p: 2,
      }}
      onFocus={() =>
        cityReducer.cities.length === 0 && dispatch(getAllCities())
      }
      loading={cityReducer?.loading}
      value={value || ""}
      onKeyDown={(event: any) => {
        if (event.key === "Enter") {
          const selectedCity = cityReducer?.cities?.find((site: any) => {
            const siteName = getTranslatedValue(site?.siteName)?.toUpperCase();
            const value = getTranslatedValue(event.target.value)?.toUpperCase();
            return siteName === value;
          });
          if (selectedCity) {
            setSelectedSiteId(selectedCity);
            handleNavigate(selectedCity);
          }
        }
      }}
      renderInput={(params) => (
        <TextField
          sx={{ textTransform: "capitalize" }}
          name="parking-input-4"
          type="text"
          variant="standard"
          {...params}
          placeholder={t("searchbar")}
          InputProps={{
            disableUnderline: true,
            onChange: (event) => {
              const enteredSiteName = event?.target?.value?.toUpperCase();
              setValue(enteredSiteName);
              const selectedCity = cityReducer?.cities?.find((city: any) => {
                const siteName = getTranslatedValue(
                  city?.siteName
                )?.toUpperCase();
                return siteName === enteredSiteName;
              });
              if (selectedCity) {
                setSelectedSiteId(selectedCity);
              }
            },
            ...params.InputProps,
          }}
        />
      )}
      onChange={handleOptionSelect}
      componentsProps={{
        clearIndicator: {
          title: t("Effacer"),
        },
      }}
    />
  );
};

export const SearchParking: FC<any> = ({
  width,
  getSelectedSite,
  searchButton,
  noBorders,
  startDate,
  endDate,
  bgcolor,
  targetIcon,
  noBorderRadiusRight,
}) => {
  //** Hooks
  const cityReducer = useSelector((state: any) => state.city);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language.toUpperCase();

  // ** States
  const [value, setValue] = React.useState<any>();
  const [selectedSiteId, setSelectedSiteId] = React.useState<any | null>("");

  // ** Get selectedSiteID callback function
  useEffect(() => {
    getSelectedSite(selectedSiteId);
    //eslint-disable-next-line
  }, [selectedSiteId]);

  // **
  const getTranslatedValue = (value: any) => {
    if (value && typeof value === "object") {
      return value[currentLanguage] || value["FR"];
    }
    return value;
  };

  const handleNavigate = (city: any) => {
    if (city) {
      const siteName = getTranslatedValue(city?.siteName)?.toLowerCase();
      const navigationPath =
        city?.id === "f2b6d97a-9082-4352-9436-5aedb1c8abe7"
          ? `/ville/${siteName}`
          : `/parkings/search/${siteName}`;

      navigate(navigationPath, {
        state: {
          cityID: city?.id,
          bookingType: "reservation",
          startDate: startDate,
          endDate: endDate,
        },
      });
    }
  };

  const sortedCities = [...(cityReducer?.cities || [])].sort(
    (a: any, b: any) => {
      const nameA = getTranslatedValue(a?.siteName).toUpperCase();
      const nameB = getTranslatedValue(b?.siteName).toUpperCase();
      return nameA.localeCompare(nameB);
    }
  );

  const handleOptionSelect = (event: any, option: any) => {
    if (option) {
      const value = getTranslatedValue(option?.value)?.toUpperCase();
      const selectedCity = cityReducer?.cities?.find((site: any) => {
        const siteName = getTranslatedValue(site?.siteName)?.toUpperCase();
        return siteName === value;
      });
      if (selectedCity) {
        setSelectedSiteId(selectedCity);
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        cursor: "pointer",
        alignItems: "center",
        borderRadius: {
          md: noBorderRadiusRight ? "5px 0px 0px 5px" : "5px",
          xs: "5px",
        },
        border: noBorders ? "none" : "1px solid #D9D9D9",
        width: width === "responsive" ? { md: "40%", xs: "96%" } : width,
        p: 1,
        bgcolor: bgcolor,
        maxWidth: "100%",
      }}
    >
      <Autocomplete
        id="free-solo-demo"
        freeSolo
        options={sortedCities?.map((option: any) => ({
          label: getTranslatedValue(option?.siteName)?.toUpperCase(),
          value: getTranslatedValue(option?.siteName)?.toUpperCase(),
        }))}
        renderInput={(params) => (
          <TextField
            sx={{ textTransform: "capitalize" }}
            name="parking-input-4"
            type="text"
            variant="standard"
            {...params}
            placeholder={t("searchbar")}
            InputProps={{
              disableUnderline: true,
              onChange: (event) => {
                const enteredSiteName = event?.target?.value?.toUpperCase();
                setValue(enteredSiteName);
                const selectedCity = cityReducer?.cities?.find((city: any) => {
                  const siteName = getTranslatedValue(
                    city?.siteName
                  )?.toUpperCase();
                  return siteName === enteredSiteName;
                });
                if (selectedCity) {
                  setSelectedSiteId(selectedCity);
                }
              },
              ...params.InputProps,
            }}
          />
        )}
        onKeyDown={(event: any) => {
          if (event.key === "Enter") {
            const value = getTranslatedValue(event.target.value)?.toUpperCase();
            const selectedCity = cityReducer?.cities?.find((site: any) => {
              const siteName = getTranslatedValue(
                site?.siteName
              )?.toUpperCase();
              return siteName === value;
            });
            if (selectedCity) {
              setSelectedSiteId(selectedCity);
              handleNavigate(selectedCity);
            }
          }
        }}
        sx={{ w: "100%", ml: 1, flex: 1 }}
        value={value || ""}
        onFocus={() =>
          cityReducer?.cities?.length === 0 && dispatch(getAllCities())
        }
        loading={cityReducer?.loading}
        onChange={handleOptionSelect}
        componentsProps={{
          clearIndicator: {
            title: t("Effacer"),
          },
        }}
      />
      {searchButton && (
        <IconButton onClick={() => handleNavigate(selectedSiteId)}>
          <Magnify sx={{ color: "#8A8A8A", mr: 1 }} />
        </IconButton>
      )}
    </Box>
  );
};
export const SearchParkingResponsive: FC<any> = ({ getSelectedSite }) => {
  const [selectedSiteId, setSelectedSiteId] = React.useState<string | null>("");
  const [value, setValue] = React.useState<any>();

  //** navigation
  const navigate = useNavigate();

  //** Hooks
  const cityReducer = useSelector((state: any) => state.city);

  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language.toUpperCase();

  // ** Get selectedSiteID callback function
  useEffect(() => {
    getSelectedSite(selectedSiteId);
    //eslint-disable-next-line
  }, [selectedSiteId]);

  // **
  const getTranslatedValue = (value: any) => {
    if (value && typeof value === "object") {
      return value[currentLanguage] || value["FR"];
    }
    return value;
  };

  const handleNavigate = (site: any) => {
    const siteName = getTranslatedValue(site?.siteName)?.toLowerCase();
    const navigationPath =
      site?.id === "f2b6d97a-9082-4352-9436-5aedb1c8abe7"
        ? `/ville/${siteName}`
        : `/parkings/search/${siteName}`;

    navigate(navigationPath, {
      state: {
        cityID: site?.id,
      },
    });
  };

  const handleOptionSelect = (event: any, option: any) => {
    if (option) {
      const value = getTranslatedValue(option?.value)?.toUpperCase();
      const selectedCity = cityReducer?.cities?.find((site: any) => {
        const siteName = getTranslatedValue(site?.siteName)?.toUpperCase();
        return siteName === value;
      });
      if (selectedCity) {
        setSelectedSiteId(selectedCity);
      }
    }
  };

  const sortedCities = [...(cityReducer?.cities || [])].sort(
    (a: any, b: any) => {
      const nameA = getTranslatedValue(a?.siteName).toUpperCase();
      const nameB = getTranslatedValue(b?.siteName).toUpperCase();
      return nameA.localeCompare(nameB);
    }
  );

  return (
    <Box
      sx={{
        borderBottom: "2px solid #D9D9D9",
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: { md: "40%", xs: "90%" },
      }}
    >
      <Autocomplete
        fullWidth
        freeSolo
        id="parking-input-5"
        sx={{ w: "100%", ml: 1, flex: 1 }}
        value={value || ""}
        onKeyDown={(event: any) => {
          if (event.key === "Enter") {
            const selectedCity = cityReducer?.cities?.find((site: any) => {
              const siteName = getTranslatedValue(
                site?.siteName
              )?.toUpperCase();
              const value = getTranslatedValue(
                event?.target?.value
              )?.toUpperCase();
              return siteName === value;
            });
            if (selectedCity) {
              setSelectedSiteId(selectedCity);
              handleNavigate(selectedCity);
            }
          }
        }}
        options={sortedCities?.map((site: any) => ({
          label: getTranslatedValue(site?.siteName)?.toUpperCase(),
          value: getTranslatedValue(site?.siteName)?.toUpperCase(),
        }))}
        renderInput={(params) => (
          <TextField
            type="text"
            name="parking-input-6"
            variant="standard"
            {...params}
            placeholder={t("searchbar")}
            InputProps={{
              disableUnderline: true,
              onChange: (event) => {
                const enteredSiteName = event?.target?.value?.toUpperCase();
                setValue(enteredSiteName);
                const selectedCity = cityReducer?.cities?.find((city: any) => {
                  const siteName = getTranslatedValue(
                    city?.siteName
                  )?.toUpperCase();
                  return siteName === enteredSiteName;
                });
                if (selectedCity) {
                  setSelectedSiteId(selectedCity);
                }
              },
              ...params.InputProps,
            }}
          />
        )}
        onChange={handleOptionSelect}
        componentsProps={{
          clearIndicator: {
            title: t("Effacer"),
          },
        }}
      />
      {/* <CrosshairsGps sx={{ color: "#8A8A8A", mr: 1 }} /> */}
    </Box>
  );
};
export const SearchCityNavbar: FC<any> = () => {
  //** States

  //** navigation
  const navigate = useNavigate();

  //** Hooks
  const dispatch = useAppDispatch();
  const cityReducer = useSelector((state: any) => state.city);

  const [selectedSite, setSelectedSite] = React.useState<any>();
  const [value, setValue] = React.useState<string | null>("");

  // ** translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language.toUpperCase();

  // **
  const getTranslatedValue = (value: any) => {
    if (value && typeof value === "object") {
      return value[currentLanguage] || value["FR"];
    }
    return value;
  };

  const handleNavigate = (city: any) => {
    if (city) {
      const siteName = getTranslatedValue(city?.siteName)?.toLowerCase();
      const siteID = city.id;

      const navigationPath =
        siteID === "f2b6d97a-9082-4352-9436-5aedb1c8abe7"
          ? `/ville/${siteName}`
          : `/parkings/search/${siteName}`;

      navigate(navigationPath, {
        state: { cityID: siteID },
      });
    }
  };

  const handleOptionSelect = (event: any, option: any) => {
    if (option) {
      const value = getTranslatedValue(option?.value)?.toUpperCase();
      const selectedCity = cityReducer?.cities?.find((site: any) => {
        const siteName = getTranslatedValue(site?.siteName)?.toUpperCase();
        return siteName === value;
      });
      if (selectedCity) {
        setSelectedSite(selectedCity);
      }
    }
  };

  const sortedCities = [...(cityReducer?.cities || [])].sort(
    (a: any, b: any) => {
      const nameA = getTranslatedValue(a?.siteName).toUpperCase();
      const nameB = getTranslatedValue(b?.siteName).toUpperCase();
      return nameA.localeCompare(nameB);
    }
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "119%",
      }}
    >
      <Autocomplete
        fullWidth
        id="parking-input-7"
        freeSolo
        sx={{
          w: "100%",
          ".MuiAutocomplete-input": {
            color: "white",
            fontWeight: 300,
          },
        }}
        onKeyDown={(event: any) => {
          if (event.key === "Enter") {
            const selectedCity = cityReducer?.cities?.find((site: any) => {
              const siteName = getTranslatedValue(
                site?.siteName
              )?.toUpperCase();
              const value = getTranslatedValue(
                event?.target?.value
              )?.toUpperCase();
              return siteName === value;
            });
            if (selectedCity) {
              setSelectedSite(selectedCity);
              handleNavigate(selectedCity);
            }
          }
        }}
        value={value || ""}
        options={sortedCities?.map((site: any) => ({
          label: getTranslatedValue(site?.siteName)?.toUpperCase(),
          value: getTranslatedValue(site?.siteName)?.toUpperCase(),
        }))}
        renderInput={(params) => (
          <TextField
            type="text"
            name="parking-input-8"
            variant="standard"
            {...params}
            placeholder={t("searchbar")}
            InputProps={{
              disableUnderline: true,
              onChange: (event) => {
                const enteredSiteName = event?.target?.value?.toUpperCase();
                setValue(enteredSiteName);
                const selectedCity = cityReducer?.cities?.find((city: any) => {
                  const siteName = getTranslatedValue(
                    city?.siteName
                  )?.toUpperCase();
                  return siteName === enteredSiteName;
                });
                if (selectedCity) {
                  setSelectedSite(selectedCity);
                }
              },
              ...params.InputProps,
            }}
            sx={{
              borderBottom: "1px solid #A9A9A9",
            }}
          />
        )}
        onFocus={() =>
          cityReducer?.cities?.length === 0 && dispatch(getAllCities())
        }
        onChange={handleOptionSelect}
        componentsProps={{
          clearIndicator: {
            title: t("Effacer"),
          },
        }}
      />
      <IconButton
        type="button"
        aria-label="search"
        onClick={() => handleNavigate(selectedSite)}
      >
        <Magnify sx={{ color: "white" }} />{" "}
      </IconButton>
    </Box>
  );
};
export const SearchCityModal = () => {
  //** States
  const [openDialog, setOpenDialog] = React.useState(false);

  //** navigation
  const navigate = useNavigate();

  //** Hooks
  const dispatch = useAppDispatch();
  const cityReducer = useSelector((state: any) => state.city);

  const [selectedSite, setSelectedSite] = React.useState<any>();

  const getSelectedSite = (newValue: any) => {
    setSelectedSite(newValue);
  };

  useEffect(() => {
    setSelectedSite(null);
  }, [navigate]);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <IconButton
        type="button"
        sx={{ p: "10px" }}
        aria-label="search"
        onClick={handleClickOpen}
      >
        <Magnify sx={{ color: "white", mr: 1 }} />{" "}
      </IconButton>
      <Dialog
        PaperProps={{
          sx: {
            m: 0,
            width: "100%",
            maxWidth: "100%",
            borderRadius: 0,
          },
        }}
        sx={{
          "& .MuiDialog-container": {
            justifyContent: "flex-start",
            alignItems: "flex-start",
          },
        }}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth
      >
        <Box
          sx={{
            maxWidth: "100%",
            p: 2,
            display: "flex",
            bgcolor: "#f5f5f6",
          }}
        >
          {/* <SearchParkingResponsive getSelectedSite={getSelectedSite} /> */}
          <SearchParking
            getSelectedSite={getSelectedSite}
            searchButton
            width="responsive"
          />
        </Box>
      </Dialog>
    </div>
  );
};
export const SubsSearchBox = () => {
  // ** Translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language.toUpperCase();

  // ** Router
  const navigate = useNavigate();
  const [selectedSite, setSelectedSite] = useState<any | null>("");
  // ** get selected site
  const getSelectedSite = (value: any) => {
    setSelectedSite(value);
  };

  // **
  const getTranslatedValue = (value: any) => {
    if (value && typeof value === "object") {
      return value[currentLanguage] || value["FR"];
    }
    return value;
  };

  // ** Navigate to the selected site page
  const handleNavigate = () => {
    if (selectedSite) {
      const siteName = getTranslatedValue(
        selectedSite?.siteName
      )?.toLowerCase();
      if (selectedSite.id === "f2b6d97a-9082-4352-9436-5aedb1c8abe7") {
        navigate(`/ville/${siteName}`, {
          state: { cityID: selectedSite?.id },
        });
      } else {
        navigate(`/parkings/search/${siteName}`, {
          state: { cityID: selectedSite?.id, bookingType: "reservation" },
        });
      }
    }
  };

  return (
    <Box>
      <Box
        component="form"
        sx={{
          p: 0,
          display: { md: "flex", sm: "flex", xs: "none" },
          alignItems: "center",
          bgcolor: "white",
          width: 1000,
          maxWidth: "100%",
          borderRadius: ".4rem",
          m: "auto",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "70%" }}>
          <SearchParkingAutocomplete getSelectedSite={getSelectedSite} />
        </div>
        {/* <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <Target />
        </IconButton> */}

        <IconButton
          disableRipple
          sx={{
            bgcolor: "#8a8a8a",
            height: { md: 100, xs: 125 },
            width: { md: 100, xs: 125 },
            color: "white",
            borderRadius: "0 .4rem .4rem 0",
            "&:hover": { backgroundColor: "#8a8a8a" },
          }}
          onClick={handleNavigate}
          aria-label="directions"
        >
          <Magnify sx={{ fontSize: 44 }} />
        </IconButton>
      </Box>
      <Box
        component="form"
        sx={{
          display: { md: "none", sm: "none", xs: "flex" },
          flexDirection: "column",
          gap: 2,
        }}
      >
        <SearchParkingAutocomplete getSelectedSite={getSelectedSite} />
        <Button
          disableRipple
          sx={{
            bgcolor: "#8a8a8a",
            color: "white",
            borderRadius: ".4rem",
            "&:hover": { backgroundColor: "#8a8a8a" },
          }}
          aria-label="directions"
          startIcon={<Magnify />}
          size="large"
          onClick={handleNavigate}
        >
          {t("Recherchez")}
        </Button>
      </Box>
    </Box>
  );
};
export const ReservationSearchBox = () => {
  // ** Translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language.toUpperCase();

  // ** Router
  const navigate = useNavigate();
  const location = useLocation();

  // ** States
  const [selectedSite, setSelectedSite] = useState<any | null>("");
  // ** previous entry and exit dates
  const prevStartDate = location?.state?.startDate;
  const prevEndDate = location?.state?.endDate;
  const currentMoment = moment();
  const roundedMinutes = Math.ceil(currentMoment.minutes() / 15) * 15;

  const [startDate, setStartDate] = useState<any>(
    prevStartDate
      ? prevStartDate
      : currentMoment.minutes(roundedMinutes).toDate()
  );
  const [endDate, setEndDate] = useState<any>(
    prevEndDate ? prevEndDate : startDate
  );
  const [startMinTime, setStartMinTime] = useState<any>(
    currentMoment.minutes(roundedMinutes).toDate()
  );
  const [endMinTime, setEndMinTime] = useState<any>(
    moment(startDate).add(1, "hour").toDate()
  );

  //** Functions

  // ** get selected site
  const getSelectedSite = (value: any) => {
    setSelectedSite(value);
  };

  // **
  const getTranslatedValue = (value: any) => {
    if (value && typeof value === "object") {
      return value[currentLanguage] || value["FR"];
    }
    return value;
  };

  // ** Navigate to the selected site page
  const handleNavigate = () => {
    if (selectedSite) {
      const siteName = getTranslatedValue(
        selectedSite?.siteName
      )?.toLowerCase();
      const navigationPath =
        selectedSite?.id === "f2b6d97a-9082-4352-9436-5aedb1c8abe7"
          ? `/ville/${siteName}`
          : `/parkings/search/${siteName}`;

      navigate(navigationPath, {
        state: {
          cityID: selectedSite?.id,
          bookingType: "reservation",
          startDate: startDate,
          endDate: endDate,
        },
      });
    }
  };

  //** Callback function to update date state with child state
  const getStartDate = (newValue: any) => {
    setStartDate(newValue);
  };

  // ** Reservation end date/time
  const getEndDate = (newValue: any) => {
    setEndDate(newValue);
  };

  useEffect(() => {
    if (prevStartDate) {
      setStartDate(prevStartDate);
    }
  }, [prevStartDate]);

  useEffect(() => {
    if (prevEndDate) {
      setEndDate(prevEndDate);
    }
  }, [prevEndDate]);

  useEffect(() => {
    const minTime = moment().toDate();
    if (moment(startDate).isSame(moment(), "day")) {
      minTime.setHours(moment().hours());
      minTime.setMinutes(Math.ceil(moment().minutes() / 15) * 15);
      setStartMinTime(minTime);
    } else {
      minTime.setHours(0);
      minTime.setMinutes(0);
      setStartMinTime(minTime);
    }
  }, [startDate]);

  // ** endMinTime
  useEffect(() => {
    // ** endMinDate change according to startDate if the diffrence is less than 1h
    const isSameDay = moment(endDate).isSame(moment(startDate), "day");
    if (isSameDay) {
      setEndMinTime(moment(startDate).add(1, "hour").toDate());
      const endOfDay = moment(moment(endDate).endOf("day")).diff(
        startDate,
        "minutes"
      );

      if (endOfDay < 60) {
        setEndDate(moment(endDate).add(1, "day").toDate());
      }
    } else {
      const minTime = moment(startDate).add(1, "hour").toDate();
      minTime.setHours(moment().hours());
      minTime.setMinutes(Math.ceil(moment().minutes() / 15) * 15);
      minTime.setHours(0);
      minTime.setMinutes(0);
      setEndMinTime(minTime);
    }

    if (startDate > endDate) {
      setEndDate(moment(startDate).add(1, "hour").toDate());
    }
  }, [startDate, endDate]);

  return (
    <Box>
      <Box
        component="form"
        sx={{
          p: 0,
          display: "flex",
          flexDirection: { md: "row", xs: "column" },
          width: 1000,
          maxWidth: "100%",
          borderRadius: ".4rem",
          m: "auto",
          rowGap: 2,
        }}
      >
        <Box
          component="div"
          sx={{ width: { md: "70%", xs: "100%" }, display: "inline-flex" }}
        >
          <SearchParking
            width="100%"
            getSelectedSite={getSelectedSite}
            noBorders
            startDate={startDate}
            endDate={endDate}
            bgcolor="white"
            targetIcon
            noBorderRadiusRight
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            rowGap: 2,
          }}
        >
          <CustomDatePickerNoBorder
            title="Entrée"
            getDate={getStartDate}
            date={startDate}
            minTime={startMinTime}
          />
          <CustomDatePickerNoBorder
            title="Sortie"
            getDate={getEndDate}
            date={endDate}
            minTime={endMinTime}
            minDate={startDate}
            prevDate={startDate}
          />
        </Box>

        <IconButton
          disableRipple
          sx={{
            bgcolor: "#8a8a8a",
            height: 100,
            width: 100,
            color: "white",
            borderRadius: "0 .4rem .4rem 0",
            "&:hover": { backgroundColor: "#8a8a8a" },
            display: { md: "block", xs: "none" },
          }}
          aria-label="directions"
          onClick={handleNavigate}
        >
          <Magnify sx={{ fontSize: 44 }} />
        </IconButton>
        <Button
          disableRipple
          sx={{
            bgcolor: "#8a8a8a",
            color: "white",
            borderRadius: ".4rem",
            "&:hover": { backgroundColor: "#8a8a8a" },
            display: { md: "none", xs: "flex" },
            width: "100%",
          }}
          aria-label="directions"
          startIcon={<Magnify />}
          size="large"
          onClick={handleNavigate}
        >
          {t("Calculez")}
        </Button>
      </Box>
    </Box>
  );
};
