import { Box, IconButton, Typography } from "@mui/material";
import { Linkedin } from "mdi-material-ui";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import twitterX from "../../assets/logo/twitterx.png";
import { LoginDialogBtn } from "../modals/loginModal";
const Footer = () => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);

  // open modal
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  return (
    <footer
      style={{
        padding: 50,
        backgroundColor: "#2e2e2e",
        color: "#8a8a8a",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: { md: "end", xs: "center" },
          alignItems: "center",
          mb: 6,
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "18px",
            display: { md: "flex", xs: "none" },
          }}
        >
          {t("Restons connectés")}
        </Typography>
        <a
          href="https://twitter.com/parkings_effia?lang=en"
          style={{ textDecoration: "none", color: "#8a8a8a" }}
          target="_blank"
          rel="noreferrer"
        >
          <IconButton sx={{ border: "1px solid #8a8a8a" }}>
            <img
              src={twitterX}
              alt="twitter"
              style={{ height: 24, width: 24 }}
            />
          </IconButton>
        </a>
        {/* <a
          href="https://www.linkedin.com/company/effia/"
          style={{ textDecoration: "none", color: "#8a8a8a" }}
          target="_blank"
          rel="noreferrer"
        >
          <IconButton sx={{ border: "1px solid #8a8a8a" }}>
            <Linkedin sx={{ color: "#8a8a8a" }} />
          </IconButton>{" "}
        </a> */}
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-around",
          flexDirection: { md: "row", xs: "column" },
        }}
      >
        <LoginDialogBtn buttonLink />
        <a
          href="/reglement-ordre-interieur"
          style={{ textDecoration: "none", color: "#8a8a8a" }}
        >
          {t("Règlement d’ordre intérieur")}
        </a>
        <a
          href="/cgu-cgv-mentions-legales"
          style={{ textDecoration: "none", color: "#8a8a8a" }}
        >
          {t("Conditions générales de ventes et d'utilisation")}
        </a>
        {/* <a
          href="/"
          style={{
            textDecoration: "none",
            color: "#8a8a8a",
            pointerEvents: "none",
          }}
        >
          {t("Presse")}
        </a>
        <a
          href="/"
          style={{
            textDecoration: "none",
            color: "#8a8a8a",
            pointerEvents: "none",
          }}
        >
          {t("Médias")}
        </a> */}
        <a
          href="/effia-recrute"
          style={{ textDecoration: "none", color: "#8a8a8a" }}
        >
          {t("EFFIA recrute")}
        </a>
        {/* <a
          href="/"
          style={{
            textDecoration: "none",
            color: "#8a8a8a",
            pointerEvents: "none",
          }}
        >
          Cookies
        </a> */}
      </Box>
    </footer>
  );
};

export default Footer;
