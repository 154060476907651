import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// get All Services

export const getAllServices = createAsyncThunk(
  "service/getAllServices",
  async (_, { rejectWithValue }) => {
    try {
      const result = await axios.get(`/get-all-services`, {
        withCredentials: true,
      });
      return result.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const serviceSlice = createSlice({
  name: "service",
  initialState: {
    service: {},
    services: [],
    loading: false,
    errors: null,
  },
  reducers: {
    clearErrors: (state) => {
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // get all services
      .addCase(getAllServices.pending, (state) => {
        state.services = [];
        state.loading = true;
        state.errors = null;
      })
      .addCase(getAllServices.fulfilled, (state, action) => {
        state.loading = false;
        state.errors = null;
        state.services = action.payload.Services;
      })
      .addCase(getAllServices.rejected, (state: any, action: any) => {
        state.loading = false;
        state.errors = action.payload.Description;
        state.services = [];
      });
  },
});

export default serviceSlice.reducer;
