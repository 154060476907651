// React imports
import React, { FC, useState, useEffect } from "react";

// MUI imports
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent, IconButton } from "@mui/material";

// Images imports
import Logo from "../../assets/logo/Effia-Logo.png";

// Icons import
import Close from "mdi-material-ui/Close";

// Redux imports
import { useAppDispatch } from "../../redux/hooks";
import { forgotPSWEmail } from "../../redux/auth/authSlice";
import { CustomizedLoadingBtn } from "../../components/Buttons";
import { Trans, useTranslation } from "react-i18next";

const ForgotPsw: FC<any> = ({ handleClose }) => {
  // ** Translation
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language.toUpperCase();

  // States
  const [userEmail, setUserEmail] = useState<String>("");
  //eslint-disable-next-line
  const [sent, setsent] = useState<Boolean>(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);

  // Hooks
  const dispatch = useAppDispatch();

  // Functions
  const handleSendEmail = () => {
    setLoadingBtn(true);
    setIsDisabled(true);
    const data = {
      userEmail,
      currentLanguage
    }
    dispatch(forgotPSWEmail(data)).then((res: any) => {
      setLoadingBtn(false);
      if (res.payload.Success) {
        setCountdown(30);
        // Disable the button for 30 seconds
        setTimeout(() => {
          setIsDisabled(false);
        }, 30000);
        // Save in localstorage
        const timestamp = new Date().getTime();
        localStorage.setItem("timestamp", timestamp.toString());
        setCountdown(30);
      } else {
        setLoadingBtn(false);
        setIsDisabled(false);
      }
    });
  };

  // ** Count down 30 seconds to resend the email and save the count in the local storage
  useEffect(() => {
    let timerId: NodeJS.Timeout;
    const storedTimestamp = localStorage.getItem("timestamp");
    if (storedTimestamp && countdown === 0) {
      const timestamp = parseInt(storedTimestamp);
      const currentTime = new Date().getTime();
      const elapsedTime = Math.floor((currentTime - timestamp) / 1000);
      const remainingTime = Math.max(30 - elapsedTime, 0);
      setCountdown(remainingTime);
      if (remainingTime > 0) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    } else if (countdown > 0) {
      timerId = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else {
      setIsDisabled(false);
    }

    return () => clearTimeout(timerId);
  }, [countdown]);

  return (
    <>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        <Box sx={{ textAlign: "center" }}>
          <img width={88} src={Logo} alt="" />
        </Box>
      </BootstrapDialogTitle>
      <DialogContent
        dividers
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 4,
          gap: 2,
        }}
      >
        {sent ? (
          <>
            {t("Vérifier votre courriel pour le lien")} <br />{" "}
            <span
              style={{ textDecoration: "underline", color: "#F39325" }}
              onClick={handleSendEmail}
            >
              {t("renvoyer")}
            </span>{" "}
          </>
        ) : (
          <>
            <Typography
              sx={{
                textAlign: "center",
                m: "1rem auto 2rem",
                color: "#484848",
                fontFamily: "Fira Sans, sans-serif",
                fontWeight: 300,
                fontSize: "1.9375rem",
                lineHeight: 1.4,
              }}
              variant="h3"
            >
              {t("Mot de passe oublié")}
            </Typography>
            <p
              style={{
                color: "#8a8a8a",
                fontFamily: "Fira Sans",
                fontSize: "18px",
                fontWeight: 300,
              }}
            >
              <Trans i18nKey="Saisissez l’adresse email">
                Saisissez l’adresse email que vous utilisez pour vous connecter
                au site Effia.fr. <br /> <br /> Un email vous sera envoyé pour
                réinitialiser votre mot de passe.
              </Trans>
            </p>
            <Box
              sx={{
                m: "0.5rem 0",
                border: "thin solid #d9d9d9",
                borderRadius: "0.4rem",
                p: "0.5rem .9375rem 0px .9375rem",
              }}
            >
              <TextField
                id="standard-textarea"
                name="userEmail"
                label={
                  <span>
                    {t("Adresse courriel")}
                    <sup style={{ color: "red" }}> *</sup>
                  </span>
                }
                placeholder="email@mail.com"
                type="email"
                fullWidth
                variant="standard"
                InputProps={{ disableUnderline: true, autoComplete: "email" }}
                InputLabelProps={{
                  style: { color: "#8a8a8a", fontSize: "90%" },
                }}
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </Box>
            <CustomizedLoadingBtn
              disabled={isDisabled}
              variant="contained"
              loading={loadingBtn}
              onClick={handleSendEmail}
            >
              {isDisabled
                ? `${t("renvoyer dans")} ${countdown} ${t("seconds")}`
                : t("Envoyer")}
            </CustomizedLoadingBtn>
          </>
        )}
      </DialogContent>
    </>
  );
};

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

export default ForgotPsw;
