import path from "path";
import { Cookies } from "react-cookie";
import { BroadcastChannel } from "broadcast-channel";

const cookies = new Cookies();
const logoutChannel = new BroadcastChannel("logout");
//remove item
export function GetToken() {
  const currentItem = cookies.get("x-sultan-token-1");
  let isAuth = false;

  if (currentItem === undefined || currentItem === null) {
    deleteTokenUser();
    isAuth = false;
  } else {
    isAuth = true;
  }
  return isAuth;
}
export function parseJWT(token: any) {
  if (!token) return {};
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  return JSON.parse(atob(base64));
}
export const deleteTokenUser = () => {
  cookies.remove("x-sultan-token-1");
};
export function getTokenValue() {
  return cookies.get("x-sultan-token-1");
}

export const handleLogout = () => {
  logoutChannel.postMessage("Logout");

  deleteTokenUser();
  window.location.href = "/";
};

export const logoutAllTabs = () => {
  logoutChannel.onmessage = () => {
    handleLogout();
    logoutChannel.close();
  };
};
